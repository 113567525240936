import React from "react";
import { Link } from "react-router-dom";

import PatientEngagementChart from "../patientengagement/PatientEngagementChart";
import HomeRecents from "./HomeRecents";
import HomePopulation from "./HomePopulation";
import Button from "../../components/button/Button";

import { classnames } from "../../utils";
import styles from "./styles.module.scss";

function HomeCharts(props) {
  const { risingRiskChangeList, populationRisk } = props;

  return (
    <div className={styles.homeChart}>
      <div className={styles.homeChartInfo}>
        <div className={classnames(styles.card, styles.cardContent)}>
          <div className={styles.homeChartInfoTitle}>
            <strong>Rising Risk Change</strong>
          </div>
          <HomeRecents {...risingRiskChangeList} />
          <div className={styles.detailButtonContainer}>
              <Link to="/patients/fall_risk">
                <Button className={styles.detailButton}>See Details</Button>
              </Link>
          </div>
        </div>
        <div className={classnames(styles.card, styles.cardContent)}>
          <div className={styles.homeChartInfoTitle}>
            <strong>Population Risk</strong>
            <select>
              <option>Last Week</option>
            </select>
          </div>
          <HomePopulation {...populationRisk} />
          <div className={styles.detailButtonContainer}>
              <Link to="/patients/list">
                <Button className={styles.detailButton}>See Details</Button>
              </Link>
          </div>
        </div>
      </div>
      <div className={styles.homeChartEngagement}>
        <div className={styles.card}>
          <PatientEngagementChart
            remoteData={true}
            abcUrl={props.abcUrl}
            sitStandUrl={props.sitStandUrl}
            textMsgUrl={props.textMsgUrl}
            forceRefresh={props.forceRefreshPinChar}
            onFilterChange={props.onFilterEngagement}
          />
          <div className={styles.detailButtonContainer}>
              <Link to="/patients/engagement">
                <Button
                  className={classnames(
                    styles.detailButton,
                    styles.detailButtonMargin
                  )}
                >
                  See Details
                </Button>
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCharts;
