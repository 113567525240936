import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Patient Views
import Home from "./pages/home/Home";
import PatientList from "./pages/patientlist/PatientList";
import PatientProfileAsPatient from "./pages/patient/PatientProfileAsPatient";
import PatientProfileAsAnotherRole from "./pages/patient/PatientProfileAsAnotherRole";
import PatientProfileEdit from "./pages/patientprofileedit/PatientProfileEdit";
import CommonVideoGrading from "./pages/commonvideograding/CommonVideoGrading";


// Video Assessment
import VideoAssessment from "./pages/videoassessment/VideoAssessment";

// Engagement and FallRisk
import PatientEngagement from "./pages/patientengagement/PatientEngagement";
import FallRisk from "./pages/fallrisk/FallRisk";

// Admin Users
import PatientRegistration from "./pages/patientregistration/PatientRegistration";
import DoctorRegistration from "./pages/doctorregistration/DoctorRegistration";
import NurseRegistration from "./pages/nurseregistration/NurseRegistration";
import Administration from "./pages/administration/Administration";

// Auth Views
import Login from "./pages/auth/Login";
import UnAuthorize from "./pages/auth/UnAuthorized";
import Authorize from "./pages/auth/Authorize";

// Helpers Components
import Auth0ProviderWithHistory from "./controllers/auth0/auth0-provider-with-history";
import ProtectedRoute from "./components/protected-route";

//Hospital View
import HospitalList from "./pages/hospital/HospitalList";
import HospitalRegistration from "./pages/hospitalregistration/HospitalRegistration";

const routes = {
  home: {
    index: "/",
  },
  video: {
    assessment: "/video_assessment/:uid",
  },
  patient: {
    create: "/patient/create",
    edit: "/patient/:id/edit",
    profile: "/patient_profile",
    list: "/patients/list",
    engagement: "/patients/engagement",
    fallRisk: "/patients/fall_risk",
    show_profile_for_admin: "/patient/:id/profile",
    // CommonVideoGrading :"/common_video"

  },
  doctor: {
    create: "/doctor/create",
  },
  nurse: {
    create: "/nurse/create",
  },
  administration: {
    index: "/administration",
  },
  CommonVideoGrading: {
    index: "/common_video",
  },
  hospital: {
    list: "/hospitals/list",
    create: "/hospital/create",
  },
  auth: {
    login: "/login",
    authorize: "/auth/authorize",
    unAuthorize: "/auth/unauthorized",
  },
};

const App = (props) => {
  return (
    <Switch>
      <ProtectedRoute
        path={routes.patient.create}
        component={PatientRegistration}
        requireAuthorization={true}
        permissionToAuthorize={["read:fall_risk"]}
      />
      <ProtectedRoute
        path={routes.patient.edit}
        component={PatientProfileEdit}
        requireAuthorization={true}
        permissionToAuthorize={["read:patient_profile"]}
      />
      <ProtectedRoute
        path={routes.patient.profile}
        component={PatientProfileAsPatient}
        requireAuthorization={true}
        permissionToAuthorize={["read:current_patient"]}
      />
      <ProtectedRoute
        path={routes.patient.show_profile_for_admin}
        component={PatientProfileAsAnotherRole}
        requireAuthorization={true}
        permissionToAuthorize={["read:patient_profile"]}
      />
      <ProtectedRoute
        path={routes.patient.list}
        component={PatientList}
        requireAuthorization={true}
        permissionToAuthorize={["read:patient_profile"]}
      />
      <ProtectedRoute
        path={routes.patient.engagement}
        component={PatientEngagement}
        requireAuthorization={true}
        permissionToAuthorize={["read:engagement"]}
      />
      <ProtectedRoute
        path={routes.patient.fallRisk}
        component={FallRisk}
        requireAuthorization={true}
        permissionToAuthorize={["read:fall_risk"]}
      />
      <ProtectedRoute
        path={routes.doctor.create}
        component={DoctorRegistration}
        requireAuthorization={true}
        permissionToAuthorize={["read:fall_risk"]}
      />
      <ProtectedRoute
        path={routes.nurse.create}
        component={NurseRegistration}
        requireAuthorization={true}
        permissionToAuthorize={["read:fall_risk"]}
      />
      <ProtectedRoute
        path={routes.administration.index}
        component={Administration}
        requireAuthorization={true}
        permissionToAuthorize={["read:patient_profile"]}
      />
      <ProtectedRoute
        path={routes.CommonVideoGrading.index}
        component={CommonVideoGrading}
        requireAuthorization={true}
        permissionToAuthorize={["read:patient_profile"]}
      />
      <ProtectedRoute
        requireAuthorization={true}
        path={routes.hospital.list}
        component={HospitalList}
        permissionToAuthorize={["edit:add_healthcare"]}
      />
      <ProtectedRoute
        path={routes.hospital.create}
        component={HospitalRegistration}
        requireAuthorization={true}
        permissionToAuthorize={["edit:add_healthcare"]}
      />
      <Route path={routes.auth.login} component={Login} />
      <Route path={routes.auth.unAuthorize} component={UnAuthorize} />
      <Route path={routes.video.assessment} component={VideoAssessment} />
      <ProtectedRoute
        requireAuthorization={false}
        path={routes.auth.authorize}
        component={Authorize}
      />
      <ProtectedRoute
        path={routes.home.index}
        component={Home}
        requireAuthorization={true}
        permissionToAuthorize={["read:home"]}
      />
    </Switch>
  );
};

function CustomRouter() {
  const onLogin = async () => {
    console.log("ON LOGIN");
  };

  return (
    <Router>
      <Auth0ProviderWithHistory onLogin={onLogin}>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  );
}

export default CustomRouter;
