import React, { useEffect, useState } from 'react';
import classname from "classname";

import { BsPlusCircle } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import SearchInput from "../../components/inputs/searchinput/SearchInput";

import {
    get as getRequest,
    onFailure,
    onSucess as parserSuccess,
  } from "../../api/common";
  import { GET_HOSPITAL_LIST, SEARCH_PATIENT_FALL_RISK } from "../../api/endpoints";

import HospitalListTable from "./HospitalListTable";

function HospitalList(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [hospitals, setHospitals] = useState(props.hospitals);
    const [header, setHeader] = useState(props.header);
    const [searchText, setSearchText] = useState("");

    let history = useHistory();

    const getHospitals = (response) => {
        console.log(response);
        const results = parserSuccess(response);
        if (results) {
            setHospitals(
            results.map(item => {
              return ({
                id: item._id,
                address: item.address,
                healthCareName: item.healthCareName,
                phone: item.phoneNumber,
                email: item.email,
              })
            })
          );
        } else {
            setHospitals(props.hospitals);
        }
      };

    useEffect(() => {
        Promise.all([
          getRequest(GET_HOSPITAL_LIST, getHospitals, onFailure),
        ]).then(() => {
          setIsLoaded(true);
          setIsTableLoaded(true);
        });
      }, []);

      const onSearchInputChange = evt => {
        const { value } = evt.target;
        setSearchText(value);
      }
    
      const search = evt => {
        if (evt.key === "Enter") {
          setIsTableLoaded(false);
          if (searchText === "") {
            Promise.all([
              getRequest(GET_HOSPITAL_LIST, getHospitals, onFailure),
            ]).then(() => setIsTableLoaded(true));
          } else {
            Promise.all([
              getRequest(SEARCH_PATIENT_FALL_RISK(searchText), getHospitals, onFailure),
            ]).then(() => setIsTableLoaded(true));
          }
        }
      }

    return!isLoaded ? (
        <Preloader />
      ) : (
          <Container>
            <Navbar />
            <Content>
              <div className={classname(styles.card, styles.card_white)}>
                <div className={classname(styles.flex_container, styles.table_header)}>
                  <div className={styles.patient_title}>All Hospitals</div>
                  <div className={styles.flex_container}>
                    <SearchInput
                      placeholder="Search patients..."
                      onChange={onSearchInputChange}
                      onKeyDown={search} />
                    <Button className={styles.add_button} onClick={() => history.push('/hospital/create')}>
                      <BsPlusCircle /> Add new
                    </Button>
                  </div>
                </div>
                <HospitalListTable
                  header={header}
                  hospitals={hospitals}
                  isLoaded={isTableLoaded} />
                {/* <div className={styles.table_footer}>
                  Show 20 more
                </div> */}
              </div>
            </Content>
            <Footer />
          </Container>
        );
};

HospitalList.defaultProps = {
    hospitals: [],
    header: [
      { title: "Health Care Name", attr: "healthCareName", sortable: true, sortFocus: false },
      { title: "Address", attr: "address", sortable: true, sortFocus: false },
      { title: "Phone", attr: "phone", sortable: true, sortFocus: false },
      { title: "Email", attr: "email", sortable: true, sortFocus: false },
    ]
  }

export default HospitalList;