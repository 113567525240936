import React from 'react'
import Container from '../../components/container/Container'
import styles from './styles.module.scss';
import Content from '../../components/content/Content'
import classname from "classname";
import Navbar from '../../components/navbar/Navbar'
import {useAuth0} from '@auth0/auth0-react';
import Preloader from '../../components/preloader/Preloader'

const UnAuthorize = ({location}) => {
  const {isAuthenticated, isLoading} = useAuth0();
  if (isLoading){
    return <Preloader />
  }
  return <Container>
    {isAuthenticated && <Navbar/>}
    <Content>
      <div className={classname(styles.dFlex)}>
        <h3 className={classname(styles.textPurple, styles.textCenter)}>
          This page require some special permissions, please contact to Admin
        </h3>
      </div>
    </Content>
  </Container>
}

UnAuthorize.propTypes = {

}

UnAuthorize.defaultProps = {

}

export default UnAuthorize;
