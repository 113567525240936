import React, { useState } from "react";
import {
  IoIosTrendingUp,
  IoIosTrendingDown,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { BsCircleFill, BsCircle } from "react-icons/bs";

import patientImg from "../../assets/img/patient.png";
import { classnames } from "../../utils";

import styles from "./styles.module.scss";

function HomeRecents({ patientList }) {
  const div = Math.floor(patientList.length / 2);
  const mod = patientList.length % 2;
  const dotsQ = div + mod;
  const patientsInitials = patientList.filter(e => e.riskChange > 0).slice(0, 2);

  const [patients, setPatients] = useState(patientsInitials);
  const [dotsQuantity] = useState(dotsQ);
  const [dotSelect, setDotSelect] = useState(0);

  function changePage(next) {
    const nextDot = dotSelect + next;
    if (nextDot < 0 || nextDot > dotsQuantity - 1) return;

    setDotSelect(nextDot);
    setPatients(patientList.slice(nextDot * 2, nextDot * 2 + 2));
  }

  return (
    <div className={styles.homeRecentsContent}>
      {patients.map((patient, index) => (
        <div key={index} className={styles.homeRecentsItems}>
          <div className={styles.homeRecentItemsAvatar}>
            <img
              src={patient.image}
              alt="Patient"
              className={styles.avatarImage}
            />
            <div className={styles.homeRecentItemsAvatarName}>
              <span>{patient.fullName}</span>
              <span className={styles.lightFont}>{patient.date}</span>
            </div>
          </div>
          <div className={styles.homeRecentItemsData}>
            <span className={styles.homeRecentFallRisk}>
              {patient.fallRisk}
            </span>
            <span
              className={classnames(
                styles.homeRecentRiskChange,
                patient.riskChange > 0
                  ? styles.homeRecentRiskChangeUp
                  : styles.homeRecentRiskChangeDown
              )}
            >
              {patient.riskChange > 0 ? (
                <>
                  <IoIosTrendingUp /> {patient.riskChange}
                </>
              ) : (
                  <>
                    <IoIosTrendingDown /> {Math.abs(patient.riskChange)}
                  </>
                )}
            </span>
          </div>
        </div>
      ))}
      <div className={styles.homeRecentControl}>
        <IoIosArrowBack
          className={styles.arrow}
          onClick={() => changePage(-1)}
        />
        {Array(dotsQuantity)
          .fill(0)
          .map((_, i) =>
            i === dotSelect ? (
              <BsCircleFill size={8} className={styles.dots} key={i} />
            ) : (
                <BsCircle size={8} className={styles.dots} key={i} />
              )
          )}
        <IoIosArrowForward
          className={styles.arrow}
          onClick={() => changePage(1)}
        />
      </div>
    </div>
  );
}

HomeRecents.defaultProps = {
  patientList: [
    {
      fullName: "Peter Cheung",
      date: "04/02/21",
      fallRisk: 8,
      riskChange: 2,
      image: patientImg,
    },
    {
      fullName: "Gina L. Petrovic",
      date: "04/02/21",
      fallRisk: 9,
      riskChange: -1,
      image: patientImg,
    },
    {
      fullName: "John Doe",
      date: "04/02/21",
      fallRisk: 4,
      riskChange: -2,
      image: patientImg,
    },
    {
      fullName: "Jenny Johnson",
      date: "04/02/21",
      fallRisk: 3,
      riskChange: 1,
      image: patientImg,
    },
    {
      fullName: "Bob Lacoste",
      date: "04/02/21",
      fallRisk: 6,
      riskChange: 3,
      image: patientImg,
    },
    {
      fullName: "Steve Larson",
      date: "04/02/21",
      fallRisk: 9,
      riskChange: -1,
      image: patientImg,
    },
  ],
};

export default HomeRecents;
