import React from "react";

import styles from "./Preloader.module.scss";
import { classnames } from '../../utils';
import Proptypes from 'prop-types';

function Preloader(props) {
  const {fullScreen, containerStyles} = props;
  return (
    <div className={classnames(containerStyles, fullScreen ? styles.fullScreen : '')}>
      <div className={styles.preloader}></div>
    </div>
  );
}

Preloader.propTypes = {
  containerStyles : Proptypes.any,
  fullScreen: Proptypes.bool,
}

Preloader.defaultProps = {
  containerStyles: styles.container,
  fullScreen: true,
}



export default Preloader;
