import React from "react";
import PropTypes from "prop-types";

import classname from "classname";

import styles from "./styles.module.scss";

function SelectInput(props) {
  return (
    <select
      name={props.name}
      value={props.value}
      className={classname(styles.s_input, props.className)}
      multiple={props.multiple}
      required={props.required}
      onChange={props.onChange}
    >
      {props.blank ? <option value="">{props.chooseLabel}</option> : null}
      {props.options.map((option, index) => {
        return (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        );
      })}
    </select>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  blank: PropTypes.bool,
  chooseLabel: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

SelectInput.defaultProps = {
  name: "",
  value: "",
  className: "",
  multiple: false,
  required: false,
  blank: true,
  chooseLabel: "Choose an option...",
  options: [],
  onChange: () => {},
};

export default SelectInput;
