import React from 'react';
import styles from "./Dialog.module.css";

export default function Dialog(props){
  const { children } = props;
  return(
    <div>
      <div className={styles.alert_box}>
      	{/* <div className="alert_box_header">
      		<h1></h1>
      	</div> */}
      	<div className={styles.alert_box_body}>
      	  {children}
      	</div>
      </div>
      <div className={styles.alert_overlay}></div>
    </div>
  )
}
