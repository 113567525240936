/**
 * Get base64 equivalent of image
 * @param File file
 * @returns base64ImgEquivalent
 */
export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Get person age using his/her birthday
 * @param string dateString
 * @returns personAge
 */
export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

/**
 * replace classname library with this
 */
export const classnames = (...args) => args.join(" ");

export const isArrayInArray = (compare, target) =>
  target.every((v) => compare.includes(v));

export const prettyDate = () => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date();
  return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const checkIfMobileBrowser = (window) => {
  const Navigator = window.navigator;
  const ua = Navigator.userAgent.toLowerCase();
  if (
    ua.indexOf("ipad") != -1 ||
    ua.indexOf("iphone") != -1 ||
    ua.indexOf("android") != -1 ||
    ua.indexOf("ipod") != -1 ||
    ua.indexOf("windows ce") != -1 ||
    ua.indexOf("windows phone") != -1 ||
    (Navigator.platform === "MacIntel" && Navigator.maxTouchPoints > 1)
  ) {
    return true;
  }
  return false;
};

export function round(num, decimalPlaces = 0) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}
