import React, { useEffect, useState } from "react";
import { fetchPatientProfileById, fetchDoctors, fetchSitToStandById } from "../../actions/patient";
import PatientProfile from "./PatientProfile";
import { useParams } from "react-router-dom";

const PatientProfileAsAnotherRole = (props) => {
  let { id } = useParams();
  const [patient, setPatient] = useState();
  const [picture, setPicture] = useState(id.picture);
  const [healthCareSystem, setHealthCareSystem] = useState();
  const [caregiver, setCaregiver] = useState();
  const [patientHealthInsurance, setPatientHealthInsurance] = useState();
  const [averages, setAverages] = useState([]);
  const [riskChange, setRiskChange] = useState(0);
  const [doctors, setDoctors] = useState([]);
  const [sitToStand, setSitToStand] = useState(0);

  useEffect(() => {
    async function setPatientInfo() {
      try {
        let [patient, doctors, sitToStands] = await Promise.all([
          fetchPatientProfileById(id),
          fetchDoctors(),
          fetchSitToStandById(id),
        ]);
        console.log(doctors);
        setPatient(patient.patient);
        setPicture(patient.patient.photo? patient.patient.photo: id.picture);
        setHealthCareSystem(patient.patient.healthcareSystem);
        setCaregiver(patient.patient.caregiver);
        setPatientHealthInsurance(patient.patient.patientHealthInsurance);
        setAverages(patient.averages);
        setRiskChange(patient.riskChange);
        setSitToStand(sitToStands);
        setDoctors(
          doctors.map((item) => ({
            id: item._id,
            name: `${item.firstName} ${item.lastName}`,
          }))
        );
      } catch (e) {
        console.log(e);
      }
    }

    setPatientInfo();
  }, []);

  return (
    <PatientProfile
      patient={patient}
      healthcareSystem={healthCareSystem}
      caregiver={caregiver}
      patientPicture={picture}
      patientHealthInsurance={patientHealthInsurance}
      averages={averages}
      riskChange={riskChange}
      doctors={doctors}
      sitToStand={sitToStand}
    />
  );
};

export default PatientProfileAsAnotherRole;
