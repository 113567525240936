import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import TextInput from "../../components/inputs/textinput/TextInput";
import FileInput from "../../components/inputs/fileinput/FileInput";
import PhoneInput from "../../components/inputs/phoneinput/PhoneInput";
import Button from "../../components/button/Button";

import styles from "./styles.module.scss";
import 'react-phone-number-input/style.css'

function HospitsalRegistrationForm(props) {

  return (
    <form onSubmit={props.submit}>
      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Hospital Care name</label>
          <TextInput
            name="healthCareName"
            className={styles.form_control}
            maxLength={50}
            value={props.hospital.healthCareName}
            onChange={props.onChange}
            placeholder="Hospital Care name"
            required
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Phone number</label>
          <PhoneInput
            name='phoneNumber'
            placeholder='Enter a valid phone number'
            value={props.hospital.phoneNumber}
            onChange={(value) => props.onChange({ target: { name: 'phoneNumber', value, nodeName: 'INPUT', type: 'text' } })}
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>E-mail</label>
          <TextInput
            name="email"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Email"
            value={props.hospital.email}
            required
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Address</label>
          <TextInput
            name="address"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="Address"
            value={props.hospital.address}
            required
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Address 2</label>
          <TextInput
            name="address2"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="Address 2"
            value={props.hospital.address2}
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>City</label>
          <TextInput
            name="city"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="City"
            value={props.hospital.city}
            required
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>State</label>
          <TextInput
            name="state"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="State"
            value={props.hospital.state}
            required
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Zip code</label>
          <TextInput
            name="zipCode"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="Zip Code"
            value={props.hospital.zipCode}
            required
          />
        </div>
      </div>

      <hr className={styles.r_hr} />

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Photo</label>
          <FileInput
            value={props.images.photo}
            name="photo"
            onChange={props.onChange}
            required
          />
        </div>
      </div>

      <hr className={styles.r_hr} />

      <div className={styles.flex_container_reverse}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

HospitsalRegistrationForm.propTypes = {
  genderOptions: PropTypes.array,
  raceOptions: PropTypes.array,
  maritalStatusOptions: PropTypes.array,
  employmentStatusOptions: PropTypes.array,
  onChange: PropTypes.func,
  submit: PropTypes.func,
};

HospitsalRegistrationForm.defaultProps = {
  genderOptions: [
    { id: "Female", name: "Female" },
    { id: "Male", name: "Male" },
  ],
  raceOptions: [
    { id: 1, name: "White, non-Hispanic" },
    { id: 2, name: "Black, non-Hispanic" },
    { id: 3, name: "Hispanic" },
    { id: 4, name: "Alaskian/Native American" },
    { id: 5, name: "Asian/Pacific" },
    { id: 6, name: "Prefer not to answer" },
  ],
  maritalStatusOptions: [
    { id: 1, name: "Single, never married" },
    { id: 2, name: "Married, first time" },
    { id: 3, name: "Married, 2nd+" },
    { id: 4, name: "Divorced" },
    { id: 5, name: "Widowed" },
    { id: 6, name: "Prefer not to answer" },
  ],
  employmentStatusOptions: [
    { id: 1, name: "Employed full-time" },
    { id: 2, name: "Employed part-time" },
    { id: 3, name: "Not employed" },
    { id: 4, name: "Self employed" },
    { id: 5, name: "Homemaker" },
    { id: 6, name: "Retired" },
    { id: 7, name: "Student" },
    { id: 8, name: "Prefer not to answer" },
  ],
  onChange: () => null,
  submit: () => null,
};

export default HospitsalRegistrationForm;
