import React, { useState } from "react";
import PropTypes from "prop-types";
import classname from "classname";

import { BiSortDown, BiSortUp } from "react-icons/bi";

import styles from "./styles.module.scss";

import {
  getPatientProfileURL,
  getPatientProfileURLById,
} from "../../actions/patient";

function PatientEngagementTable(props) {
  const engagementLevel = (patient) => {
    if (patient.engagement_level === 1) {
      return (
        <>
          <span className={styles.black_dot} />
          <span className={styles.white_dot} />
          <span className={styles.white_dot} />
        </>
      );
    } else if (patient.engagement_level === 2) {
      return (
        <>
          <span className={styles.black_dot} />
          <span className={styles.black_dot} />
          <span className={styles.white_dot} />
        </>
      );
    } else if (patient.engagement_level === 3) {
      return (
        <>
          <span className={styles.black_dot} />
          <span className={styles.black_dot} />
          <span className={styles.black_dot} />
        </>
      );
    }
    return null;
  };

  const sortArrows = () => {
    if (props.sortOrder === "asc") {
      return <BiSortDown />;
    } else if (props.sortOrder === "desc") {
      return <BiSortUp />;
    }
    return null;
  };

  return (
    <>
      <div className={styles.table_responsive}>
        <table className={styles.patient_table}>
          <thead>
            <tr>
              {props.header.map((column, index) => {
                if (column.sortable) {
                  return (
                    <th
                      key={index}
                      className={classname(styles.separator, styles.sortable)}
                      onClick={() => props.sort(column.title, column.attr)}
                    >
                      <div className={styles.column_head}>
                        <span style={{ marginRight: 10 }}>{column.title}</span>
                        {column.sortFocus ? sortArrows() : null}
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className={styles.separator}>
                      {column.title}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {props.patients.length > 0 && props.isLoaded
              ? props.patients.map((patient, index) => {
                return (
                  <tr key={index}>
                    <td align="left" className={styles.table_row}>
                      {patient.name}
                    </td>
                    <td align="center" className={styles.table_row}>
                      {patient.last_text}
                    </td>
                    <td align="center" className={styles.table_row}>
                      {patient.abc_scale ? parseFloat(patient.abc_scale).toFixed(2) : "-"}
                    </td>
                    <td align="center" className={styles.table_row}>
                      {patient.sit_to_stand ? parseFloat(patient.sit_to_stand).toFixed(2) : "-"}
                    </td>
                    <td align="center" className={styles.table_row}>
                      {engagementLevel(patient)}
                    </td>
                    <td align="center" className={styles.table_row}>
                      <span className={styles.fall_risk}>
                        {patient.fall_risk}
                      </span>
                    </td>
                    <td align="center" className={styles.table_row}>
                      <a
                        href={"/patient/"+patient.id+"/profile"}
                        className={styles.action_button}
                      >
                        {">"}
                      </a>
                    </td>
                  </tr>
                );
              })
              : null}
          </tbody>
        </table>
      </div>
      {!props.isLoaded ? (
        <div className={styles.table_footer}>Searching for patients...</div>
      ) : null}
      {props.patients.length < 1 && props.isLoaded ? (
        <div className={styles.table_footer}>No results found</div>
      ) : null}
    </>
  );
}

PatientEngagementTable.propTypes = {
  header: PropTypes.array,
  patients: PropTypes.array,
  sort: PropTypes.func,
  sortOrder: PropTypes.string,
  isLoaded: PropTypes.bool,
};

PatientEngagementTable.defaultProps = {
  header: [],
  patients: [],
  sort: () => { },
  sortOrder: "",
  isLoaded: false,
};

export default PatientEngagementTable;