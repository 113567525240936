import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "react-vis/dist/style.css";
import {
  XAxis,
  YAxis,
  FlexibleXYPlot,
  VerticalGridLines,
  LineMarkSeries,
} from "react-vis";

import * as graphicSettings from "./../../utils/graphic-settings";
import * as charActions from "./../../actions/char";
import { parser } from "./../../actions/char";
import Preloader from "../../components/preloader/Preloader";

import styles from "./styles.module.scss";

const MSEC_DAILY = 86400000;

const timestamp = new Date().getTime();

const colorText = "#2BCFBC";
const colorABC = "#FD802B";
const colorSit = "#F6C035";

function PatientEngagementChart(props) {
  const [loadingData, setLoadingData] = useState(false);

  const [toolTipValue, setTooltipValue] = useState("");
  const [groupBy, setGroupBy] = useState(props.defaultGroupBy);

  const { remoteData } = props;
  const [abcScaleData, setAbcScaleData] = useState(props.abcScaleData);
  const [textMessageData, setTextMessageData] = useState(props.textMessageData);
  const [sitStandData, setSitStandData] = useState(props.sitStandData);

  useEffect(() => {
    if (remoteData) {
      fetchEngagementData();
    }
  }, [props.forceRefresh]);

  useEffect(() => {
    if (groupBy && props.onFilterChange) {
      props.onFilterChange(groupBy);
    }
  }, [groupBy]);

  const fetchEngagementData = async () => {
    try {
      setLoadingData(true);
      let [rAbcData, rSitStandData, rTextMessageData] = await Promise.all([
        charActions.fetchAbcData(props.abcUrl),
        charActions.fetchSitStandData(props.sitStandUrl),
        charActions.fetchMsgData(props.textMsgUrl),
      ]);
      setAbcScaleData(parser(rAbcData, groupBy));
      setSitStandData(parser(rSitStandData, groupBy));
      setTextMessageData(parser(rTextMessageData, groupBy));
      setLoadingData(false);
    } catch (e) {
      console.log(e);
      setLoadingData(false);
    }
  };

  const onChange = evt => {
    const { value } = evt.target;
    setGroupBy(value);
  }

  return (
    <div className={styles.chart_container}>
      <div className={styles.patientChartTitle}>
        <strong>Patient Engagement</strong>
        <select onChange={onChange}>
          <option value="month">By Month</option>
          <option value="week">By Week</option>
        </select>
      </div>
      <ul className={styles.patientChartLegend}>
        <li style={{ color: colorText }}>
          <span>Text Messages</span>
        </li>
        <li style={{ color: colorABC }}>
          <span>ABC Scale</span>
        </li>
        <li style={{ color: colorSit }}>
          <span>Sit-to-Stand</span>
        </li>
      </ul>
      <div className={styles.patientChartGraph}>
        {loadingData ? <Preloader fullScreen={false} /> :
          <FlexibleXYPlot xType="ordinal">
            <VerticalGridLines />
            <XAxis />
            <YAxis />
            <LineMarkSeries
              style={{
                strokeWidth: "2px",
              }}
              lineStyle={{ stroke: colorText }}
              markStyle={{ stroke: colorText }}
              color="white"
              curve={"curveMonotoneX"}
              data={textMessageData}
            />
            <LineMarkSeries
              style={{
                strokeWidth: "2px",
              }}
              lineStyle={{ stroke: colorABC }}
              markStyle={{ stroke: colorABC }}
              color="white"
              curve={"curveMonotoneX"}
              data={abcScaleData}
            />
            <LineMarkSeries
              style={{
                strokeWidth: "2px",
              }}
              lineStyle={{ stroke: colorSit }}
              markStyle={{ stroke: colorSit }}
              color="white"
              curve={"curveMonotoneX"}
              data={sitStandData}
            />
          </FlexibleXYPlot>}
      </div>
    </div>
  );
}

PatientEngagementChart.propTypes = {
  defaultGroupBy: PropTypes.string,
  remoteData: PropTypes.bool,
  abcUrl: PropTypes.string,
  sitStandUrl: PropTypes.string,
  textMsgUrl: PropTypes.string,
  onFilterChange: PropTypes.func,
  forceRefresh: PropTypes.bool,
};

PatientEngagementChart.defaultProps = {
  defaultGroupBy: graphicSettings.DEFAULT_GROUP_BY,
  textMessageData: [],
  abcScaleData: [],
  sitStandData: [],
  remoteData: true,
};

export default PatientEngagementChart;