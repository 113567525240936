import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import styles from "./styles.module.scss";

function DateInput(props) {
  return (
    <input
      name={props.name}
      type="date"
      className={classname(styles.d_input, props.className)}
      placeholder={props.placeholder}
      required={props.required}
      max={props.max}
      min={props.min}
      onChange={props.onChange}
    />
  );
}

DateInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  name: "",
  value: "",
  className: "",
  required: false,
  max: new Date().toISOString().slice(0, 10),
  min: "1900-01-01",
  onChange: () => {},
};

export default DateInput;
