import React from "react";

import Button from "../../components/button/Button";

import styles from "./styles.module.scss";
import { IoCheckmarkCircleOutline, IoWarningOutline } from "react-icons/io5";

function VideoAssessmentDone(props) {
  const {
    success = true,
    titleGood = "All Done!",
    bodyGood = "Your video was uploaded successfully! We’ll notifiy you if there are any changes in your fall risk.",
    titleBad = "Oh, no! something went wrong.",
    bodyBad = "Looks like your video didn’t get through. Press the button to try again. ",
  } = props;

  return (
    <>
      {success ? (
        <>
          <p>
            <IoCheckmarkCircleOutline className={styles.successIcon} />
          </p>
          <h2>{titleGood}</h2>
          <p className={styles.body2}>{bodyGood}</p>
        </>
      ) : (
        <>
          <p>
            <IoWarningOutline className={styles.failIcon} />
          </p>
          <h2>{titleBad}</h2>
          <p className={styles.body2}>{bodyBad}</p>
          <Button
            className={styles.buttonFill}
            onClick={() => {
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        </>
      )}
    </>
  );
}

export default VideoAssessmentDone;
