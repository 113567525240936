import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import styles from "./styles.module.scss";

import { BsSearch } from "react-icons/bs";

function SearchInput(props) {
  return (
    <div className={styles.search_container}>
      <input
        // value={props.value}
        type="search"
        className={classname(styles.s_input, props.className)}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown} />
      <BsSearch />
    </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
}

SearchInput.defaultProps = {
  value: "",
  placeholder: "",
  onChange: () => { },
  onKeyDown: () => { },
}

export default SearchInput;