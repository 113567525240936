import React from "react";

import styles from "./Footer.module.scss";

function Footer() {
  return (
    <div className={styles.footer}>
      <p>Copyright © 2021 Roxie Health Inc. All Rights Reserved</p>
    </div>
  );
}

export default Footer;
