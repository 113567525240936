import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";

import HospitsalRegistrationForm from "./HospitsalRegistrationForm";

import styles from "./styles.module.scss";
import classname from "classname";
import alertify from "alertifyjs";

import {
  post as postRequest,
  onFailure,
} from "../../api/common";
import {
  SAVE_HOSPITAL,
} from "../../api/endpoints";

import { toBase64 } from "../../utils";

function HospitalRegistration(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hospital, setHospital] = useState(props.hospital);
  const [images, setImages] = useState(props.images);

  const history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    console.log(hospital);
  }, [hospital]);

  const onChange = async (evt) => {
    const { name, value, nodeName, type } = evt.target;
    if (nodeName === "INPUT") {
      if (type === "text")
        setHospital((hospital) => ({ ...hospital, [name]: value }));
      if (type === "date" && value)
        setHospital((hospital) => ({ ...hospital, [name]: new Date(value).toISOString().replace('Z', '+00:00') }));
      if (type === "date" && !value)
        setHospital((hospital) => ({ ...hospital, [name]: "" }));
      if (type === "radio")
        setHospital((hospital) => ({ ...hospital, [name]: value }));
      if (type === "file") {
        if (value.length > 0) {
          const b64Img = await toBase64(evt.target.files[0]);
          const imgUrl = URL.createObjectURL(evt.target.files[0]);
          setHospital((hospital) => ({ ...hospital, [name]: b64Img }));
          setImages((images) => ({ ...images, [name]: imgUrl }));
        }
      }
    }
    if (nodeName === "SELECT") {
      setHospital((hospital) => ({
        ...hospital,
        [name]: value,
      }));
    }
  };

  const onSaveHospitalSuccess = (response) => {
    console.log("RESPUESTA");
    console.log(response);
    // const results = parserSuccess(response);
    alertify.alert(
      "Hospital information saved!",
      "Your hospital information has been successfully saved. You will be redirected to the hospital list page",
      function () {
        history.push("/hospitals/list");
      })
      .set("closable", false)
  };

  /* eslint eqeqeq: "off" */
  const validateData = () => {
    if (
      hospital.healthCareName == "" ||
      hospital.phoneNumber == "" ||
      hospital.email == "" ||
      hospital.address == "" ||
      hospital.photo == ""
    ) {
      return false;
    }
    return true;
  };

  const submit = (evt) => {
    evt.preventDefault();
    setIsLoaded(false);

    // Parse Data
    console.log("Parse Data");

    if (validateData()) {
      let hospitalInfo = {
        healthCareName: hospital.healthCareName,
        phoneNumber: hospital.phoneNumber,
        email: hospital.email,
        address: hospital.address,
        address2: hospital.address2,
        photo: hospital.photo,
      };
      console.log(hospitalInfo);
      Promise.all([
        postRequest(SAVE_HOSPITAL, hospitalInfo, onSaveHospitalSuccess, onFailure),
      ]).then(() => {
        setIsLoaded(true);
        setHospital(props.hospital);
        setImages(props.images);
      });
    } else {
      console.log("Hospital cannot be saved");
      setIsLoaded(true);
    }
  };

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <p className={styles.title}>Hospital Registration</p>
          <div className={classname(styles.card, styles.card_white)}>
            <HospitsalRegistrationForm
              images={images}
              onChange={onChange}
              submit={submit}
              hospital={hospital}
            />
          </div>
        </Content>
        <Footer />
      </Container>
    );
}

HospitalRegistration.defaultProps = {
  hospital: {
    healthCareName: "",
    phoneNumber: "",
    email: "",
    address: "",
    photo: "",
  },
  images: {
    photo: "",
  },
};

export default HospitalRegistration;
