import axios from "axios";
import {
  getPatientInfoUrl,
  GET_DOCTOR_LIST,
  getPatientInfoUrlById,
  getSitToStandById,
} from "../api/endpoints";

export const fetchPatientProfile = async (phoneNumber) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(getPatientInfoUrl(phoneNumber), {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  return res.data.results;
};

export const fetchPatientProfileById = async (id) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(getPatientInfoUrlById(id), {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
  });
  return res.data.results;
};

export const fetchSitToStandById = async (id) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(getSitToStandById(id), {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
  });
  return res.data.results;
};


export const fetchDoctors = async () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(GET_DOCTOR_LIST, {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(res);
  return res.data.payload;
};

export const fetchVideo = async (url) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  return res.data;
};

export const getPatientProfileURL = (patient) => {
  return "/patient/" + patient.phone + "/profile";
};

export const getPatientProfileURLById = (patient) => {
  return "/patient/" + patient.id + "/profile";
};
