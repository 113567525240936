import React from "react";

import { prettyDate } from "../../utils";
import styles from "./styles.module.scss";

const currentDate = prettyDate();

function HomeMessage({ name }) {
  return (
    <div className={styles.homeMessage}>
      <small>{currentDate}</small>
      <h2>Hello, {name}</h2>
      <span>What do you need to know today?</span>
    </div>
  );
}

HomeMessage.defaultProps = { name: "User" };

export default HomeMessage;
