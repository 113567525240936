import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { DEFAULT_SCOPE_AUTH0 } from "../../actions/auth";

const Auth0ProviderWithHistory = ({ children, onLogin }) => {
  const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const onRedirectCallback = (appState) => {
    if (onLogin) onLogin();
    console.log('RETURN TO', appState?.returnTo);
    console.log('LOCATION PATH', window.location.path)
    history.replace('/auth/authorize');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={DEFAULT_SCOPE_AUTH0}
      redirectUri={`${window.origin}/auth/authorize`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
