import React, { useState } from "react";
import PropTypes from "prop-types";
import classname from "classname";

import { IoIosTrendingUp, IoIosTrendingDown } from "react-icons/io";
import { BiSortDown, BiSortUp } from "react-icons/bi";

import styles from "./styles.module.scss";

import {
  getPatientProfileURL,
  getPatientProfileURLById,
} from "../../actions/patient";

function PatientListTable(props) {
  const fallRisk = (patient) => {
    if (patient.risk_change) {
      if (patient.risk_change > 0) {
        return (
          <span className={styles.risk_change_up}>
            <IoIosTrendingUp />
            {patient.risk_change}
          </span>
        );
      } else {
        return (
          <span className={styles.risk_change_down}>
            <IoIosTrendingDown />
            {patient.risk_change * -1}
          </span>
        );
      }
    } else {
      return <span>-</span>;
    }
  };

  const sortArrows = () => {
    if (props.sortOrder === "asc") {
      return <BiSortDown />;
    } else if (props.sortOrder === "desc") {
      return <BiSortUp />;
    }
    return null;
  };

  return (
    <>
      <div className={styles.table_responsive}>
        <table className={styles.patient_table}>
          <thead>
            <tr>
              {props.header.map((column, index) => {
                if (column.sortable) {
                  return (
                    <th
                      key={index}
                      className={classname(styles.separator, styles.sortable)}
                      onClick={() => props.sort(column.title, column.attr)}
                    >
                      <div className={styles.column_head}>
                        <span style={{ marginRight: 10 }}>{column.title}</span>
                        {column.sortFocus ? sortArrows() : null}
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className={styles.separator}>
                      {column.title}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {props.patients.length > 0 && props.isLoaded
              ? props.patients.map((patient, index) => {
                  return (
                    <tr key={index}>
                      <td align="left" className={styles.table_row}>
                        {patient.name}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {patient.sex}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {patient.age}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {patient.last_contact}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {patient.phone}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {fallRisk(patient)}
                      </td>
                      <td align="center" className={styles.table_row}>
                        <span className={styles.fall_risk}>
                          {patient.fall_risk ? patient.fall_risk : "-"}
                        </span>
                      </td>
                      <td align="center" className={styles.table_row}>
                        <a
                          href={getPatientProfileURLById(patient)}
                          className={styles.action_button}
                        >
                          {">"}
                        </a>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      {!props.isLoaded ? (
        <div className={styles.table_footer}>Searching for patients...</div>
      ) : null}
      {props.patients.length < 1 && props.isLoaded ? (
        <div className={styles.table_footer}>No results found</div>
      ) : null}
    </>
  );
}

PatientListTable.propTypes = {
  header: PropTypes.array,
  patients: PropTypes.array,
  sort: PropTypes.func,
  sortOrder: PropTypes.string,
  isLoaded: PropTypes.bool,
};

PatientListTable.defaultProps = {
  header: [],
  patients: [],
  sort: () => {},
  sortOrder: "",
  isLoaded: false,
};

export default PatientListTable;
