import axios from "axios";

export const get = async (url, onSucess, onError) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  console.log(url);
  try {
    const response = await axios.get(url, {
      headers: {
        "Organization-Id": currentUser && currentUser.user_metadata ?
        currentUser.user_metadata.healthcareSystem._id : '',
      },
    });
    onSucess(response);
  } catch (error) {
    onError(error);
  }
};

export const post = async (url, params, onSucess, onError) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  try {
    console.log(url);
    console.log(params);
    const response = await axios.post(url, params, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Organization-Id": currentUser && currentUser.user_metadata ?
        currentUser.user_metadata.healthcareSystem._id : '',
      },
    });
    onSucess(response);
  } catch (error) {
    onError(error);
  }
};

export const postNoOrg = async (url, params, onSucess, onError) => {
  try {
    console.log(url);
    console.log(params);
    const response = await axios.post(url, params, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    onSucess(response);
  } catch (error) {
    onError(error);
  }
};

export const onSucess = (response) => {
  if (response.status !== 200) {
    onFailure(response.statusText);
    return null;
  }

  const { code, results, error } = response.data;
  if (code !== 200) {
    onFailure(error);
    return null;
  }

  return results;
};

export const onFailure = (error) => {
  // alert("There is an error in server, please reload the page");
  console.error(error);
};
