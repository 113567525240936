import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import TextInput from "../../components/inputs/textinput/TextInput";
import SelectInput from "../../components/inputs/selectinput/SelectInput";
import DateInput from "../../components/inputs/dateinput/DateInput";
import FileInput from "../../components/inputs/fileinput/FileInput";
import RadioButton from "../../components/inputs/radiobutton/RadioButton";
import PhoneInput from "../../components/inputs/phoneinput/PhoneInput";
import Button from "../../components/button/Button";

import styles from "./styles.module.scss";
import 'react-phone-number-input/style.css'

function PatientProfileEditForm(props) {
  const getDateFormat= date => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    let dateString =  yyyy + "-" + mm + "-" + dd   ;
    return dateString;
  }
  return (
    <form onSubmit={props.submit}>
      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>First name <span className={styles.required}>*</span></label>
          <TextInput
            name="firstName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="First Name"
            value={props.patient.firstName}
            required
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Middle name</label>
          <TextInput
            name="middleName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Middle Name"
            value={props.patient.middleName}
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Last name <span className={styles.required}>*</span></label>
          <TextInput
            name="lastName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Last Name"
            value={props.patient.lastName}
            required
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Gender</label>
          <SelectInput
            name="sex"
            className={styles.form_control}
            options={props.genderOptions}
            onChange={props.onChange}
            value={props.patient.sex}
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Date of birth <span className={styles.required}>*</span></label>
          <DateInput
            name="birthDayDate"
            type="date"
            className={styles.form_control}
            onChange={props.onChange}
            value={getDateFormat(new Date(props.patient.birthDayDate))}
            required
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Phone number <span className={styles.required}>*</span></label>
          <PhoneInput
            name='phoneNumber'
            placeholder='Enter a valid phone number'
            value={props.patient.phoneNumber}
            onChange={(value) => props.onChange({ target: { name: 'phoneNumber', value, nodeName: 'INPUT', type: 'text' } })}
            required
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>E-mail</label>
          <TextInput
            name="email"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Email"
            value={props.patient.email}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Address</label>
          <TextInput
            name="address"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="Address"
            value={props.patient.address}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Address 2</label>
          <TextInput
            name="address2"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="Address 2"
            value={props.patient.address2}
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>City</label>
          <TextInput
            name="city"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="City"
            value={props.patient.city}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>State</label>
          <TextInput
            name="state"
            className={styles.form_control}
            maxLength={100}
            onChange={props.onChange}
            placeholder="state"
            value={props.patient.state}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Zip code</label>
          <TextInput
            name="zipCode"
            className={styles.form_control}
            maxLength={6}
            onChange={props.onChange}
            placeholder="Zip Code"
            value={props.patient.zipCode}
          />
        </div>
      </div>

      <hr className={styles.r_hr} />

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Photo</label>

          <FileInput
            value={props.images.photo}
            name="photo"
            onChange={props.onChange}
            disabled
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Insurance card front</label>
          <FileInput
            value={props.images.insuranceCardFront}
            name="insuranceCardFront"
            onChange={props.onChange}
            disabled
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Insurance card back</label>
          <FileInput
            value={props.images.insuranceCardBack}
            name="insuranceCardBack"
            onChange={props.onChange}
            disabled
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Driver's license</label>
          <FileInput
            value={props.images.driversLicense}
            name="driversLicense"
            onChange={props.onChange}
            disabled
          />
        </div>
      </div>

      <hr className={styles.r_hr} />

      <p className={styles.title}>Caregiver information</p>
      {props.patient.caregiver  && (
        <>
      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>First name</label>
          <TextInput
            name="caregiverFirstName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="First Name"
            required={props.requiredFields()}
            value={props.patient.caregiverFirstName}
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Middle name</label>
          <TextInput
            name="caregiverMiddleName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Middle Name"
            value={props.patient.caregiverMiddleName}
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Last name</label>
          <TextInput
            name="caregiverLastName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Last Name"
            required={props.requiredFields()}
            value={props.patient.caregiverLastName}
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Phone number</label>
          <PhoneInput
            name='caregiverPhoneNumber'
            placeholder='Enter a valid phone number'
            value={props.patient.caregiverPhoneNumber}
            onChange={(value) => props.onChange({ target: { name: 'caregiverPhoneNumber', value, nodeName: 'INPUT', type: 'text' } })}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>E-mail</label>
          <TextInput
            name="caregiverEmail"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Email"
            required={props.requiredFields()}
            value={props.patient.caregiverEmail}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
        </div>
      </div>
      </>
      )}
      <hr className={styles.r_hr} />

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Race</label>
          <RadioButton
            name="race"
            options={props.raceOptions}
            onChange={props.onChange}
            value={props.patient.race}
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Marital status</label>
          <RadioButton
            name="maritalStatus"
            options={props.maritalStatusOptions}
            onChange={props.onChange}
            value={props.patient.maritalStatus}
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Employment status</label>
          <RadioButton
            name="employmentStatus"
            options={props.employmentStatusOptions}
            onChange={props.onChange}
            value={props.patient.employmentStatus}
          />
        </div>
        <div className={styles.p_info}></div>
      </div>
      <div className={styles.flex_container_reverse}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

PatientProfileEditForm.propTypes = {
  genderOptions: PropTypes.array,
  raceOptions: PropTypes.array,
  maritalStatusOptions: PropTypes.array,
  employmentStatusOptions: PropTypes.array,
  onChange: PropTypes.func,
  submit: PropTypes.func,
};

PatientProfileEditForm.defaultProps = {
  genderOptions: [
    { id: "Female", name: "Female" },
    { id: "Male", name: "Male" },
  ],
  raceOptions: [
    { id: 1, name: "White, non-Hispanic" },
    { id: 2, name: "Black, non-Hispanic" },
    { id: 3, name: "Hispanic" },
    { id: 4, name: "Alaskian/Native American" },
    { id: 5, name: "Asian/Pacific" },
    { id: 6, name: "Prefer not to answer" },
  ],
  maritalStatusOptions: [
    { id: 1, name: "Single, never married" },
    { id: 2, name: "Married, first time" },
    { id: 3, name: "Married, 2nd+" },
    { id: 4, name: "Divorced" },
    { id: 5, name: "Widowed" },
    { id: 6, name: "Prefer not to answer" },
  ],
  employmentStatusOptions: [
    { id: 1, name: "Employed full-time" },
    { id: 2, name: "Employed part-time" },
    { id: 3, name: "Not employed" },
    { id: 4, name: "Self employed" },
    { id: 5, name: "Homemaker" },
    { id: 6, name: "Retired" },
    { id: 7, name: "Student" },
    { id: 8, name: "Prefer not to answer" },
  ],
  onChange: () => null,
  submit: () => null,
};

export default PatientProfileEditForm;
