import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "react-vis/dist/style.css";
import {
  XAxis,
  YAxis,
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
} from "react-vis";

import * as graphicSettings from "./../../utils/graphic-settings";
import * as charActions from "./../../actions/char";
import { parser3 } from "./../../actions/char";
import Preloader from "../../components/preloader/Preloader";

import styles from "./styles.module.scss";

const colorText = "#2329d6";

function FallRiskChart(props) {
  const [loadingData, setLoadingData] = useState(false);

  const [toolTipValue, setTooltipValue] = useState("");
  const [groupBy, setGroupBy] = useState(props.defaultGroupBy);

  const { remoteData } = props;
  const [fallRiskData, setFallRiskData] = useState(props.fallRiskData);

  useEffect(() => {
    if (remoteData) {
      fetchEngagementData();
    }
  }, [props.forceRefresh]);

  useEffect(() => {
    if (groupBy && props.onFilterChange) {
      props.onFilterChange(groupBy);
    }
  }, [groupBy]);

  const fetchEngagementData = async () => {
    try {
      setLoadingData(true);
      let [rFallRiskData] = await Promise.all([
        charActions.fetchFallRiskData2(props.fallRiskUrl),
      ]);
      setFallRiskData(parser3(props.patients, groupBy));
      setTimeout(
        () => 
        {
          setGroupBy("week");
          setLoadingData(false)}, 
        800
      );
      
    } catch (e) {
      console.log(e);
      setLoadingData(false);
    }
  };

  const higherNumber = () => {
    const n = Math.max(...fallRiskData.map(o => o.y), 0);
    return n;
  }

  // const onChange = evt => {
  //   const { value } = evt.target;
  //   setGroupBy(value);
  // }

  return (
    <div className={styles.chart_container}>
      <div className={styles.patientChartTitle}>
        <strong>Population Risk</strong>
        <select>
        <option value="week">By Risk</option>
        </select>
      </div>
      <ul className={styles.patientChartLegend}>
        <li style={{ color: colorText }}>
          <span>Number of patients</span>
        </li>
      </ul>
      <div className={styles.patientChartGraph}>
        {loadingData ? <Preloader fullScreen={false} /> :
          <FlexibleXYPlot
            height={300}
            xType="ordinal"
            yDomain={[0, higherNumber() + 5]}>
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              barWidth={0.4}
              color={colorText}
              data={fallRiskData}
            />
            <LabelSeries
              data={fallRiskData.map(obj => {
                return { ...obj, label: obj.y.toString() }
              })}
              labelAnchorX="middle"
              labelAnchorY="text-after-edge" />
          </FlexibleXYPlot>
        }
      </div>
    </div>
  );
}

FallRiskChart.propTypes = {
  defaultGroupBy: PropTypes.string,
  remoteData: PropTypes.bool,
  fallRiskUrl: PropTypes.string,
  onFilterChange: PropTypes.func,
  forceRefresh: PropTypes.bool,
};

FallRiskChart.defaultProps = {
  defaultGroupBy: graphicSettings.DEFAULT_GROUP_BY,
  fallRiskData: [],
  remoteData: true,
};

export default FallRiskChart;