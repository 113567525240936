import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import TextInput from "../../components/inputs/textinput/TextInput";
import PhoneInput from "../../components/inputs/phoneinput/PhoneInput";
import Button from "../../components/button/Button";

import styles from "./styles.module.scss";

function DoctorRegistrationForm(props) {
  return (
    <form onSubmit={props.submit}>
      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>First name</label>
          <TextInput
            name="firstName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            value={props.doctor.firstName}
            placeholder="First Name"
            required
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Middle name</label>
          <TextInput
            name="middleName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            value={props.doctor.middleName}
            placeholder="Middle Name"
          />
        </div>

        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Last name</label>
          <TextInput
            name="lastName"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            value={props.doctor.lastName}
            placeholder="Last Name"
            required
          />
        </div>
      </div>

      <div className={styles.flex_container}>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>Phone number</label>
          <PhoneInput
            name='phoneNumber'
            placeholder='Enter a valid phone number'
            value={props.doctor.phoneNumber}
            onChange={(value) => props.onChange({ target: { name: 'phoneNumber', value, nodeName: 'INPUT', type: 'text' } })}
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
          <label>E-mail</label>
          <TextInput
            name="email"
            className={styles.form_control}
            maxLength={50}
            onChange={props.onChange}
            placeholder="Email"
            value={props.doctor.email}
            required
          />
        </div>
        <div className={classname(styles.form_group, styles.p_info)}>
        </div>
      </div>

      <div className={styles.flex_container_reverse}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

DoctorRegistrationForm.propTypes = {
  onChange: PropTypes.func,
  submit: PropTypes.func,
};

DoctorRegistrationForm.defaultProps = {
  onChange: () => null,
  submit: () => null,
};

export default DoctorRegistrationForm;
