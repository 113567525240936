import React from "react";
import PropTypes from "prop-types";

import classname from "classname";
import { IconContext } from "react-icons";
import { IoIosTrendingUp, IoIosTrendingDown } from "react-icons/io";

import styles from "./styles.module.scss";

function PatientProfileSummary({
  surveyValue,
  assessmentValue,
  riskChangeValue,
  fallRiskValue,
}) {
  return (
    <>
      <div className={styles.cardPatientSummary}>
        <div
          className={classname(styles.card, styles.cardGlance, styles.cardDark)}
        >
          <div className={styles.cardPatientSummaryTitle}>
            <span>ABC</span>
            <br />
            <span>Scale</span>
          </div>
          <div className={styles.cardPatientSummaryValue}>
            <span className={styles.cardPatientSummaryValueVal}>
              {`${Math.floor(surveyValue)} %`}
            </span>
            {/* <span className={styles.cardPatientSummaryValueSymbol}>
              &nbsp;Avg.
            </span> */}
          </div>
        </div>
        <div
          className={classname(styles.card, styles.cardGlance, styles.cardDark)}
        >
          <div className={styles.cardPatientSummaryTitle}>
            <span>5x Sit to</span>
            <br />
            <span>Stand</span>
          </div>
          <div className={styles.cardPatientSummaryValue}>
            <span className={styles.cardPatientSummaryValueVal}>
              {assessmentValue}
            </span>
            <span className={styles.cardPatientSummaryValueSymbol}>
              &nbsp;Seconds
            </span>
          </div>
        </div>
        <div
          className={classname(
            styles.card,
            styles.cardGlance,
            styles.cardGreen
          )}
        >
          <div className={styles.cardPatientSummaryTitle}>
            <span>Risk</span>
            <br />
            <span>Change</span>
          </div>
          <div className={styles.cardPatientSummaryValue}>
            <IconContext.Provider
              value={{ className: styles.cardPatientSummaryValueSymbol }}
            >
              <div>
                {riskChangeValue > 0 ? (
                  <IoIosTrendingUp />
                ) : (
                  <IoIosTrendingDown />
                )}
                &nbsp;
              </div>
            </IconContext.Provider>

            <span className={styles.cardPatientSummaryValueVal}>
              {Math.abs(riskChangeValue)}
            </span>
          </div>
        </div>
        <div
          className={classname(
            styles.card,
            styles.cardGlance,
            styles.cardOrange
          )}
        >
          <div className={styles.cardPatientSummaryTitle}>
            <span>Fall</span>
            <br />
            <span>Risk</span>
          </div>
          <div className={styles.cardPatientSummaryValue}>
            <span className={styles.cardPatientSummaryValueVal}>
              {fallRiskValue}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

PatientProfileSummary.propTypes = {
  surveyValue: PropTypes.number.isRequired,
  assessmentValue: PropTypes.string.isRequired,
  riskChangeValue: PropTypes.number.isRequired,
  fallRiskValue: PropTypes.number.isRequired,
};

export default PatientProfileSummary;
