import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Preloader from "../preloader/Preloader";
import Proptypes from "prop-types";
import { can } from "../../actions/auth";
import { authorize } from "../../utils/authorize";

const withAuthorization = (WrappedComponent, options = {}) => {
  return (props) => {
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    console.log(user);
    const [status, setStatus] = useState("checking");

    useEffect(() => {
      let waRevisionAuth = null;
      if (isAuthenticated) {
        authorize([], getAccessTokenSilently).finally(() => {
          console.log("loaded permissions and stored");
          loadPermissions();
        });
      } else {
        waRevisionAuth = setTimeout(() => {
          // setStatus('unauthorized');
          setStatus("authorized");
        }, 1000);
      }
      return () => {
        if (waRevisionAuth) {
          clearTimeout(waRevisionAuth);
        }
      };
    }, [isAuthenticated, getAccessTokenSilently]);

    const loadPermissions = () => {
      const { requireAuthorization, permissionToAuthorize } = options;
      const canView = can(permissionToAuthorize);
      if (requireAuthorization) {
        setStatus(canView ? 'authorized' : 'unauthorized');
        // setStatus("authorized");
      } else {
        // SKIP AUTHORIZATION FOR THIS VIEW
        setStatus("authorized");
      }
    };

    if (status === "checking") {
      return <Preloader />;
    }

    if (status === "authorized") {
      return <WrappedComponent {...props} />;
    }

    if (status === "unauthorized") {
      return <Redirect to={{ pathname: "/auth/unauthorized" }} />;
    }

    if (status === "unauthenticated") {
      return <Redirect to={{ pathname: "/login" }} />;
    }
  };
};

const ProtectedRoute = ({
  component,
  requireAuthorization,
  permissionToAuthorize,
  ...args
}) => {
  const AuthComponent = withAuthenticationRequired(component, {
    onRedirecting: () => <Preloader />,
  });

  const AuthorizedComponent = withAuthorization(AuthComponent, {
    requireAuthorization,
    permissionToAuthorize,
  });

  return <Route component={AuthorizedComponent} {...args} />;
};

ProtectedRoute.propTypes = {
  component: Proptypes.any,
  requireAuthorization: Proptypes.bool,
  permissionToAuthorize: Proptypes.array,
};

ProtectedRoute.defaultProps = {
  requireAuthorization: false,
  permissionToAuthorize: [],
};

export default ProtectedRoute;
