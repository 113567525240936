import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { BiSpreadsheet } from "react-icons/bi";
import { ImArrowRight } from "react-icons/im";

import styles from "./styles.module.scss";
import classname from "classname";

function AdministrationBoard(props) {
  return (
    <div>
      <div className={styles.flex_container}>
        <Link to="/doctor/create" className={styles.options}>
          <BiSpreadsheet size={20} /> Doctor registration <ImArrowRight size={20} />
        </Link>
        <Link to="/nurse/create" className={styles.options}>
          <BiSpreadsheet size={20} /> Nurse registration <ImArrowRight size={20} />
        </Link>
      </div>
    </div>
  )
}

export default AdministrationBoard;
