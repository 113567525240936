import axios from "axios";
import { getStatisticsFallRiskUrl } from "../api/endpoints";

export const fetchMsgData = async (url) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(url);
  return res.data.results;
};

export const fetchAbcData = async (url) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(url);
  return res.data.results;
};

export const fetchSitStandData = async (url) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(url);
  return res.data.results;
};

export const fetchFallRiskData = async (params = {}, filter) => {
  const { phone_number } = params;
  const url = getStatisticsFallRiskUrl(phone_number, filter);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, params, {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(url);
  return res.data.results;
};

export const fetchFallRiskData2 = async (url) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const res = await axios.get(url, {
    headers: {
      "Organization-Id": currentUser.user_metadata.healthcareSystem._id,
    },
  });
  console.log(url);
  return res.data.results;
};

export const parser = (engagementData = [], groupBy = "month") => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (groupBy === "month") {
    return engagementData
      .sort((a, b) => a._id.year - b._id.year || a._id.month - b._id.month)
      .map((ed) => ({
        x: `${MONTHS[ed._id.month - 1]}-${ed._id.year}`,
        y: ed.count,
      }));
  } else {
    if (groupBy === "week") {
      return engagementData.map((ed) => ({
        x: `${ed._id.week-31}`,
        y: ed.count,
      }));
    }
  }
};

const getAverageResult = (engagementData)=>{
  let averageResult = 0;
  engagementData.results.forEach(function(item, index) {
    averageResult += Number(item.answer.value)*100;
});
  return parseInt(averageResult / engagementData.count);
}

export const parser2 = (engagementData = [], groupBy = "month") => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (groupBy === "month") {
    let sortedEngementData = engagementData.sort((a, b) => a._id.year - b._id.year || a._id.month - b._id.month);
    let graphData = []
    sortedEngementData.forEach(ele=>{
      let sortedRes = ele.results.sort((a,b)  => new Date(a.createdAt) - new Date(b.createdAt));
      graphData.push({
        x: new Date(sortedRes[0].createdAt).toDateString(),
        y: Number(sortedRes[0].answer.value),
      })
      // .forEach(elem=>{
      //   graphData.push({
      //     x: new Date(elem.createdAt).toDateString(),
      //     y: Number(elem.answer.value),
      //   })
      // })
    })
    return graphData;
  } else {
    if (groupBy === "week") {
      return engagementData.map((ed) => ({
        x: `${ed._id.week-31}`,
        y: getAverageResult(ed),
      }));
    }
  }
};

export const parser3 = (engagementData = [], groupBy = "") => {
  let fallRisk = {
    '-1': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
    '8': 0,
    '9': 0,
    '10': 0,
  };
    
  let graphData = [];
  engagementData.forEach(ele=>{
    fallRisk = {
      ...fallRisk,
      [ele.fall_risk]: ++fallRisk[ele.fall_risk]
    };
  });
  
  for(let elem in fallRisk){
    graphData.push({
      x: elem,
      y: fallRisk[elem],
    })
  }; 
  return graphData;
};
