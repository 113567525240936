const DOMAIN = process.env.REACT_APP_API_DOMAIN;
const MAIL_DOMAIN = process.env.REACT_APP_MAIL_DOMAIN;

export const API_DOMAIN = `${DOMAIN}/api/v1`;
export const CAREGIVERS_LIST = `${DOMAIN}/api/v1/caregiver/list`;
export const HEALTHCARE_SYSTEM_LIST = `${DOMAIN}/api/v1/healthcare_system/list`;

export const SAVE_PATIENT = `${DOMAIN}/api/v1/patient/save`;
export const UPDATE_PATIENT = `${DOMAIN}/api/v1/patient/update`;
export const GET_PATIENT_LIST = `${DOMAIN}/api/v1/patient/listWithFallRisk`;
export const GET_RISING_RISK_CHANGE_LIST = `${DOMAIN}/api/v1/patient/risingriskchangelist`;
export const GET_PATIENT_ENGAGEMENT_LIST = `${DOMAIN}/api/v1/patient/patientEngagement`;
export const GET_POPULATION_RISK = `${DOMAIN}/api/v1/patient/populationrisk`;
export const VALIDATE_VIDEO_UID = `${DOMAIN}/api/v1/videos/check_uid`;

export const GET_DOCTOR_LIST = `${MAIL_DOMAIN}/v1/roxi/doctors/list_refs`;
export const REF_DOCTOR = `${MAIL_DOMAIN}/v1/roxi/doctors/ref_doctor`;
export const DOWNLOAD = (pdf_file) => `${MAIL_DOMAIN}${pdf_file}`;

export const SAVE_DOCTOR = `${DOMAIN}/api/v1/doctor/save`;
export const SAVE_NURSE = `${DOMAIN}/api/v1/nurse/save`;
export const SEARCH_PATIENT_ENGAGEMENT = (name) =>
  `${DOMAIN}/api/v1/patient/searchpatient/${name}`;
export const SEARCH_PATIENT_FALL_RISK = (name) =>
  `${DOMAIN}/api/v1/patient/getpatientbyname/${name}`;

export const getPatientInfoUrl = (phone_number) =>
  `${DOMAIN}/api/v1/patient/getpatientbynumber/${phone_number}`;
export const getPatientInfoUrlById = (id) =>
  `${DOMAIN}/api/v1/patient/getpatientbyid/${id}`;
export const getSitToStandById = (id) =>
  `${DOMAIN}/api/v1/videos/getSitToStandValue?patientId=${id}`;

export const getStatisticsABCUrl = (phone_number, filter = "month") =>
  `${DOMAIN}/api/v1/patient/getResults/${phone_number}/abc_scale/${filter}`;
export const getStatisticsFallRiskUrl = (phone_number, filter = "month") =>
  `${DOMAIN}/api/v1/patient/getResults/${phone_number}/fall_risk/${filter}`;
export const getStatisticsFiveStandUrl = (phone_number, filter = "month") =>
  `${DOMAIN}/api/v1/patient/getResults/${phone_number}/five_sitstand/${filter}`;
export const getStatisticsTextMsgUrl = (phone_number, filter = "month") =>
  `${DOMAIN}/api/v1/patient/getResults/${phone_number}/text_messages/${filter}`;


export const getVideoByPatientIdUrl = (id) =>
  `${DOMAIN}/api/v1/videos/getVideoByPatientId/${id}`;

export const getAllVideoByPatientIdUrl = (id) =>
  `${DOMAIN}/api/v1/videos/getAllVideoByPatientId/${id}`;

  export const getAllVideoBy = () =>
  `${DOMAIN}/api/v1/videos/getAllVideo`;

//region Auth Module
export const URL_ROLES_FOR_CURRENT_USER = `${API_DOMAIN}/auth/roles`;
export const URL_FETCH_CURRENT_USER_PROFILE = `${API_DOMAIN}/patient/authuser`;
//endregion

export const SEND_MESSAGE = `${DOMAIN}/api/v1/patient/sendmessage`;
export const UPDATE_GRADING = `${DOMAIN}/api/v1/videos/update`;
export const INITIATE_WORKFLOW = `${DOMAIN}/api/v1/workflow/init`;

export const GET_HOSPITAL_LIST = `${DOMAIN}/api/v1/healthcare_system/list`;
export const SAVE_HOSPITAL = `${DOMAIN}/api/v1/healthcare_system/save`;

export const SAVE_FILE_DOCUMENT = `${API_DOMAIN}/patient/authuser`;