import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "react-vis/dist/style.css";
import {
  XAxis,
  YAxis,
  FlexibleXYPlot,
  VerticalGridLines,
  LineMarkSeries,
} from "react-vis";

import styles from "./styles.module.scss";

import * as graphicSettings from "./../../utils/graphic-settings";
import * as charActions from "./../../actions/char";
import { parser } from "./../../actions/char";
import Preloader from "../../components/preloader/Preloader";

const colorText = "#2BCFBC";
const colorABC = "#FD802B";
const colorSit = "#F6C035";

function PatientProfileEngagementChart(props) {
  const [loadingData, setLoadingData] = useState(false);

  const [toolTipValue, setTooltipValue] = useState("");
  const [groupBy, setGroupBy] = useState(props.defaultGroupBy);

  const { remoteData } = props;
  const [abcScaleData, setAbcScaleData] = useState(props.abcScaleData);
  const [textMessageData, setTextMessageData] = useState(props.textMessageData);
  const [sitStandData, setSitStandData] = useState(props.sitStandData);

  useEffect(() => {
    if (remoteData) {
      fetchEngagementData();
    }
  }, [props.forceRefresh]);

  useEffect(() => {
    if (groupBy && props.onFilterChange) {
      props.onFilterChange(groupBy);
    }
  }, [groupBy]);

  const fetchEngagementData = async () => {
    try {
      setLoadingData(true);
      let [rAbcData, rSitStandData, rTextMessageData] = await Promise.all([
        charActions.fetchAbcData(props.abcUrl),
        charActions.fetchSitStandData(props.sitStandUrl),
        charActions.fetchMsgData(props.textMsgUrl),
      ]);
      setAbcScaleData(parser(rAbcData, groupBy));
      setSitStandData(parser(rSitStandData, groupBy));
      setTextMessageData(parser(rTextMessageData, groupBy));
      setLoadingData(false);
    } catch (e) {
      console.log(e);
      setLoadingData(false);
    }
  };

  return (
    <>
      <div className={styles.patientChartTitle}>
        <strong>Engagement</strong>
        <select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
          {graphicSettings.PERMITTED_GROUP_BY.map((pg) => (
            <option key={pg.id} value={pg.id}>
              {pg.text}
            </option>
          ))}
        </select>
      </div>
      <ul className={styles.patientChartLegend}>
        {
          <li style={{ color: colorText }}>
            <span>Text Messages</span>
          </li>
        }
        <li style={{ color: colorABC }}>
          <span>ABC Scale</span>
        </li>
        <li style={{ color: colorSit }}>
          <span>Sit-to-Stand</span>
        </li>
      </ul>
      <div className={styles.patientChartGraph}>
        {loadingData ? (
          <Preloader fullScreen={false} />
        ) : (
            <FlexibleXYPlot xType={"ordinal"}>
              <VerticalGridLines />
              <XAxis tickLabelAngle={-45} />
              <YAxis />

              {
                <LineMarkSeries
                  style={{
                    strokeWidth: "2px",
                  }}
                  lineStyle={{ stroke: colorText }}
                  markStyle={{ stroke: colorText }}
                  color="white"
                  curve={"curveMonotoneX"}
                  data={textMessageData}
                />
              }
              <LineMarkSeries
                style={{
                  strokeWidth: "2px",
                }}
                lineStyle={{ stroke: colorABC }}
                markStyle={{ stroke: colorABC }}
                animation={"wobbly"}
                color="white"
                curve={"curveMonotoneX"}
                data={abcScaleData}
              />
              <LineMarkSeries
                style={{
                  strokeWidth: "2px",
                }}
                animation={"wobbly"}
                lineStyle={{ stroke: colorSit }}
                markStyle={{ stroke: colorSit }}
                color="white"
                curve={"curveMonotoneX"}
                data={sitStandData}
              />
            </FlexibleXYPlot>
          )}
      </div>
    </>
  );
}

PatientProfileEngagementChart.propTypes = {
  textMessageData: PropTypes.array,
  abcScaleData: PropTypes.array,
  sitStandData: PropTypes.array,
  defaultGroupBy: PropTypes.string,
  remoteData: PropTypes.bool,
  abcUrl: PropTypes.string,
  sitStandUrl: PropTypes.string,
  textMsgUrl: PropTypes.string,
  onFilterChange: PropTypes.func,
  forceRefresh: PropTypes.bool,
};

PatientProfileEngagementChart.defaultProps = {
  defaultGroupBy: graphicSettings.DEFAULT_GROUP_BY,
  textMessageData: [],
  abcScaleData: [],
  sitStandData: [],
  remoteData: true,
};

export default PatientProfileEngagementChart;
