import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Button from "../../components/button/Button";

import { checkIfMobileBrowser } from "../../utils";
import styles from "./styles.module.scss";

// Extern Libs Imports
const ADD_PIPE_JS_CDN = "//cdn.addpipe.com/2.0/pipe.js";
const ADD_PIPE_CSS_CDN = "//cdn.addpipe.com/2.0/pipe.css";

function VideoRecord(props) {
  const {
    videoPipeParams = {
      size: { width: 640, height: 360 },
      qualityurl: "avq/360p.xml",
      accountHash: "aed788deb768f6bad551aa030929a7c8",
      eid: "7OiOEr",
      showMenu: 0,
      mrt: 300, // Time recorded
      sis: 0,
      asv: 0,
      mv: 1,
      st: 1,
      ssb: 1,
      dup: 0,
      srec: 0,
      lang: `${window.origin}/en.xml`,
    },
    videoUID = 555,
    onUpload = (success, extraParams) => ({
      success,
      extraParams,
    }),
  } = props;

  const [loadPipe, setLoadPipe] = useState(false);
  const mobile = checkIfMobileBrowser(window);

  function uploadedSuccess(...uploadSuccessParams) {
    onUpload(true, uploadSuccessParams);
  }

  function uploadFail(...uploadFailParams) {
    onUpload(false, uploadFailParams);
  }

  function recordedInserted(recorderObject) {
    recorderObject.onUploadDone = uploadedSuccess;
    recorderObject.onSaveOk = uploadedSuccess;
    recorderObject.onVideoUploadSuccess = uploadedSuccess;
    recorderObject.onDesktopVideoUploadSuccess = uploadedSuccess;

    recorderObject.onVideoUploadFailed = uploadFail;
    recorderObject.onDesktopVideoUploadFailed = uploadFail;
  }

  function initRecorder() {
    const params = {
      ...videoPipeParams,
      payload: `{"uid":"${videoUID}"}`,
    };

    // Get AddPipe Object
    const { PipeSDK } = window;
    PipeSDK.insert("video", params, recordedInserted);
  }

  useEffect(() => {
    function onLoadScript() {
      setLoadPipe(true);

      const params = {
        ...videoPipeParams,
        payload: `{"uid":"${videoUID}"}`,
      };

      // Get AddPipe Object
      if (mobile) {
        const { PipeSDK } = window;
        PipeSDK.insert("video", params, recordedInserted);
      }
    }

    const script = document.createElement("script");
    script.src = ADD_PIPE_JS_CDN;
    script.async = true;
    script.onload = onLoadScript;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href={ADD_PIPE_CSS_CDN} />
      </Helmet>
      {mobile ? (
        <div id="video" className={styles.addPipeButton}></div>
      ) : (
        <>
          {loadPipe && (
            <Button className={styles.buttonFill} onClick={initRecorder}>
              Record Video
            </Button>
          )}
          <div id="video"></div>
        </>
      )}
    </>
  );
}

export default VideoRecord;
