import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as authActions from "../../actions/auth";
import PatientProfile from "./PatientProfile";
import { fetchPatientProfile } from "../../actions/patient";

const PatientProfileAsPatient = (props) => {
  const { user } = useAuth0();
  const [patient, setPatient] = useState();
  const [picture, setPicture] = useState(user.picture);
  const [healthCareSystem, setHealthCareSystem] = useState();
  const [caregiver, setCaregiver] = useState();
  const [patientHealthInsurance, setPatientHealthInsurance] = useState();
  const [averages, setAverages] = useState([]);
  const [riskChange, setRiskChange] = useState(0);
  useEffect(() => {
    async function setPatientInfo() {
      try {
        let currentUser = await authActions.fetchUserProfile(user.sub);
        const { phoneNumber } = currentUser.user_metadata;
        const patient = await fetchPatientProfile(phoneNumber);
        setPatient(patient.patient);
        setPicture(patient.patient.photo);
        setHealthCareSystem(patient.patient.healthcareSystem);
        setCaregiver(patient.patient.caregiver);
        setPatientHealthInsurance(patient.patient.patientHealthInsurance);
        setAverages(patient.averages);
        setRiskChange(patient.riskChange);
      } catch (e) {
        console.log(e);
      }
    }

    setPatientInfo();
  }, []);

  return (
    <PatientProfile
      patient={patient}
      healthcareSystem={healthCareSystem}
      caregiver={caregiver}
      patientPicture={picture}
      patientHealthInsurance={patientHealthInsurance}
      averages={averages}
      riskChange={riskChange}
    />
  );
};

export default PatientProfileAsPatient;
