import React from 'react'
import Container from '../../components/container/Container'
import styles from './styles.module.scss';
import Content from '../../components/content/Content'
import classname from "classname";
import Navbar from '../../components/navbar/Navbar'
import Button from '../../components/button/Button'
import {useAuth0} from '@auth0/auth0-react';
import Preloader from '../../components/preloader/Preloader'
import { Redirect } from 'react-router-dom'

const Login = ({location}) => {
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  if (isLoading){
    return <Preloader />
  }
  if (isAuthenticated) {
    return <Redirect to={{pathname: '/patient/+16033656803/profile', state: {from: location}}} />
  }
  return <Container>
    {isAuthenticated && <Navbar/>}
    <Content>
      <div className={classname(styles.dFlex)}>
        <h2 className={classname(styles.textPurple, styles.textCenter)}>Provide your Credentials</h2>
        <Button onClick={loginWithRedirect} className={styles.buttonLogin} type={'submit'}>Login</Button>
      </div>
    </Content>
  </Container>
}

Login.propTypes = {

}

Login.defaultProps = {

}

export default Login;
