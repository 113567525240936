import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./Navbar.module.scss";

import className from "classname";
import { IconContext } from "react-icons";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import UserPP from "../../assets/img/avatar.png";
import Button from '../button/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { can } from '../../actions/auth';
import * as authActions from '../../actions/auth'
import {
  useLocation
} from "react-router-dom";
import logo from "../../assets/img/logo.png";

function Navbar({ UserProfilePicture }) {
  const [showMenu, setShowMenu] = useState(false);
  const { logout, isAuthenticated, user } = useAuth0();

  const [picture, setPicture] = useState(user.picture);
  const { nickname } = user;
  let location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (isAuthenticated && user) {
      authActions.fetchUserProfile(user.sub).then((currentUser) => {
        const userMetadata = currentUser.user_metadata;
        if (userMetadata && userMetadata.usertype === 'Patient') {
          setPicture(userMetadata.photo);
        }
      }).catch(e => console.log(e));
    }
  }, [isAuthenticated, user]);


  return (
    <>
      <nav className={styles.navbarContainer}>
        <div className={styles.navbarLogo}>
          <Link to="/" className={styles.navbarLogoLink}>
            <img src={logo} alt="Roxie Logo" />
          </Link>
        </div>
        <div
          className={styles.navbarToggle}
          onClick={() => setShowMenu(!showMenu)}
        >
          <IconContext.Provider value={{ className: styles.navbarIcon }}>
            <div>{showMenu ? <FaTimes /> : <FaBars />}</div>
          </IconContext.Provider>
        </div>
        <div
          className={className(styles.navbarItem, {
            [styles.navbarItemHide]: !showMenu,
          })}
        >
          <ul className={styles.navbarMenu}>
            {
              can(['read:home']) && <li className={className(styles.menuItem, "/" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/" className={styles.menuLink}>
                  Overview
                </Link>
              </li>
            }

            {
              can(['read:fall_risk']) && <li className={className(styles.menuItem, "/patients/fall_risk" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/patients/fall_risk" className={styles.menuLink}>
                  Fall Risk
                </Link>
              </li>
            }
            {
              can(['read:engagement']) && <li className={className(styles.menuItem, "/patients/engagement" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/patients/engagement" className={styles.menuLink}>
                  Engagement
              </Link>
              </li>
            }
            {
              can(['read:current_patient']) && <li className={className(styles.menuItem, "/patient_profile" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/patient_profile" className={styles.menuLink}>
                  Patient Profile
                </Link>
              </li>
            }
            {
              can(['read:patient_profile']) && <li className={className(styles.menuItem, "/patients/list" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/patients/list" className={styles.menuLink}>
                  Patients
                </Link>
              </li>
            }
            {
              can(["edit:add_healthcare"]) && <li className={className(styles.menuItem, "/hospitals/list" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/hospitals/list" className={styles.menuLink}>
                  Hospitals
                </Link>
              </li>
            }
            {
              can(['read:patient_profile']) && <li className={className(styles.menuItem, "/administration" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/administration" className={styles.menuLink}>
                  Administration
                </Link>
              </li>
            }
            {
              /* can(['read:patient_profile']) && <li className={className(styles.menuItem, "/patients/common_video" === currentPath ? styles.menuItemSelected : '')}>
                <Link to="/common_video" className={styles.menuLink}>
                  Videos
                </Link>
              </li> */
            }
          </ul>
        </div>
        <div
          className={className(styles.navbarItem, {
            [styles.navbarItemHide]: !showMenu,
          })}
        >

          <div className={className(styles.dFlex)}>
            <span>{nickname}</span>
            {isAuthenticated && <Button onClick={() => {
              localStorage.removeItem("currentUser");
              logout({
                returnTo: `${window.origin}/login`
              })
            }} className={styles.textLogout}>
              Logout
            </Button>}
          </div>
          {UserProfilePicture ? (
            <img
              src={picture}
              alt="Patient Profile"
              className={styles.navbarPictureIcon}
            />
          ) : (
              <IconContext.Provider value={{ className: styles.navbarIcon }}>
                <div>
                  <FaUserCircle />
                </div>
              </IconContext.Provider>
            )}
        </div>
      </nav>
    </>
  );
}

Navbar.propTypes = {
  UserFullName: PropTypes.string,
  UserProfilePicture: PropTypes.string,
};

Navbar.defaultProps = {
  UserFullName: "Cathy Jones",
  UserProfilePicture: UserPP,
};

export default Navbar;
