import React from "react";
import PropTypes from "prop-types";

import classname from "classname";

import styles from "./styles.module.scss";

import { AiFillCamera } from "react-icons/ai";

function FileInput(props) {
  return (
    <label htmlFor={props.name}>
      {props.value == "" ?
        <div className={styles.no_image}>
          <AiFillCamera size={30} />
        </div> :
        <div
          className={styles.uploaded_image}
          style={{ backgroundImage: "url(" + props.value + ")" }} />
      }

      <input
        id={props.name}
        name={props.name}
        type="file"
        className={classname(styles.f_input, props.className)}
        required={props.required}
        accept="image/*"
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </label>
  );
}

FileInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
}

FileInput.defaultProps = {
  value: "",
  name: "",
  className: "",
  required: false,
  onChange: () => { }
}

export default FileInput;