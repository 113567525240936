import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";

import NurseRegistrationForm from "./NurseRegistrationForm";

import styles from "./styles.module.scss";
import classname from "classname";
import alertify from "alertifyjs";

import {
  get as getRequest,
  post as postRequest,
  onFailure,
  onSucess as parserSuccess,
} from "../../api/common";
import {
  SAVE_NURSE,
} from "../../api/endpoints";
import * as authActions from './../../actions/auth';

function NurseRegistration(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [nurse, setNurse] = useState(props.nurse);

  const history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const onChange = async (evt) => {
    const { name, value } = evt.target;
    setNurse(nurse => ({ ...nurse, [name]: value }))
  };

  const onSaveNurseSuccess = (response) => {
    // const results = parserSuccess(response);
    alertify.alert(
      "Nurse  information saved!",
      "Nurse information has been successfully saved.",
      function () {
        history.push("/administration");
      })
      .set("closable", false)
  };

  const validateData = () => {
    if (
      nurse.firstName == "" ||
      nurse.lastName == "" ||
      nurse.phoneNumber == "" ||
      nurse.email == ""
    ) {
      return false;
    }
    return true;
  };

  const submit = (evt) => {
    evt.preventDefault();
    setIsLoaded(false);

    // Parse Data
    console.log("Parse Data");

    if (validateData()) {
      Promise.all([
        postRequest(SAVE_NURSE, nurse, onSaveNurseSuccess, onFailure),
      ]).then(() => {
        setIsLoaded(true);
        setNurse(props.nurse);
      });
    } else {
      console.log("Nurse cannot be saved");
      setIsLoaded(true);
    }
  }

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <p className={styles.title}>Nurse Registration</p>
          <div className={classname(styles.card, styles.card_white)}>
            <NurseRegistrationForm
              nurse={nurse}
              onChange={onChange}
              submit={submit}
            />
          </div>
        </Content>
        <Footer />
      </Container>
    );
}

NurseRegistration.defaultProps = {
  nurse: {
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  },
};

export default NurseRegistration;
