import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";

import HomeMessage from "./HomeMessage";
import HomeCharts from "./HomeCharts";

import { useAuth0 } from "@auth0/auth0-react";

import {
  get as getRequest,
  onFailure,
  onSucess as parserSuccess,
} from "../../api/common";

import {
  GET_RISING_RISK_CHANGE_LIST,
  GET_POPULATION_RISK,
  getStatisticsABCUrl,
  getStatisticsFiveStandUrl,
  getStatisticsTextMsgUrl,
} from "../../api/endpoints";

import { parseRisingRiskChangeList } from "../../utils/parsers";

function Home(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [risingRiskChangeList, setRisingRiskChangeList] = useState([]);
  const [populationRisk, setPopulationRisk] = useState({
    low: 0,
    medium: 0,
    high: 0,
  });
  const [abcUrl, setAbcUrl] = useState('')
  const [sitStandUrl, setSitStandUrl] = useState('');
  const [textMsgUrl, setTextMsgUrl] = useState('');
  const [forceRefreshPinChar, setForceRefreshPinChar] = useState(true);
  const { user } = useAuth0();
  let history = useHistory();

  const getRisingRiskChangeList = (response) => {
    const results = parserSuccess(response);
    if (!results) return;

    const parsedRisingRiskChangeList = parseRisingRiskChangeList(results);
    setRisingRiskChangeList(parsedRisingRiskChangeList);
  };

  const getPopulationRisk = (response) => {
    const results = parserSuccess(response);
    if (!results) return;

    const parsedPopulationRisk = Object.fromEntries(
      Object.entries(results).map((item) => [
        item[0],
        Math.round(item[1] * 100),
      ])
    );
    setPopulationRisk(parsedPopulationRisk);
  };

  const onFilterEngagement = (selectedFilter) => {
    console.log(selectedFilter);
    setAbcUrl(getStatisticsABCUrl("all", selectedFilter));
    setSitStandUrl(getStatisticsFiveStandUrl("all", selectedFilter));
    setTextMsgUrl(getStatisticsTextMsgUrl("all", selectedFilter));
    // This update all children component
    setForceRefreshPinChar(!forceRefreshPinChar);
  }

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && currentUser.email === 'superadmin@roxie.com'){
      history.push('/hospitals/list')
    }
    Promise.all([
      getRequest(
        GET_RISING_RISK_CHANGE_LIST,
        getRisingRiskChangeList,
        onFailure
      ),
      getRequest(GET_POPULATION_RISK, getPopulationRisk, onFailure),
      setAbcUrl(getStatisticsABCUrl("all")),
      setSitStandUrl(getStatisticsFiveStandUrl("all")),
      setTextMsgUrl(getStatisticsTextMsgUrl("all"))
    ]).then(() => setIsLoaded(true));
  }, []);

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <div className={styles.homeContent}>
            <HomeMessage name={user.nickname} />
            <HomeCharts
              risingRiskChangeList={risingRiskChangeList}
              populationRisk={populationRisk}
              abcUrl={abcUrl}
              sitStandUrl={sitStandUrl}
              textMsgUrl={textMsgUrl}
              forceRefreshPinChar={forceRefreshPinChar}
              onFilterEngagement={onFilterEngagement}
            />
          </div>
        </Content>
        <Footer />
      </Container>
    );
}

export default Home;
