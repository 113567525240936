import React from "react";
import PropTypes from "prop-types";
import PhoneInputLibrary from 'react-phone-number-input'
import TextInput from '../textinput/TextInput'

function PhoneInput(props) {
  return (
    <PhoneInputLibrary
      defaultCountry={props.defaultCountry}
      international={false}
      name={props.name}
      countries={props.countries}
      inputComponent={TextInput}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
      required={props.required}
    />
  )
}

PhoneInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  countries: PropTypes.array,
  defaultCountry: PropTypes.string
}

PhoneInput.defaultProps = {
  name: "",
  value: "",
  className: "",
  type: "text",
  placeholder: "",
  required: false,
  maxLength: 200,
  minLength: 1,
  onChange: () => { },
  onFocus: () => { },
  defaultCountry: 'US',
  countries: ['US']
}

export default PhoneInput;
