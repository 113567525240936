import React from "react";
import PropTypes from "prop-types";

import SelectInput from "../../components/inputs/selectinput/SelectInput";

import classname from "classname";
import { HiDownload } from "react-icons/hi";
import styles from "./styles.module.scss";

function PatientProfileTips(props) {
  const { body } = props;
  return (
    <>
      <div className={classname(styles.card, styles.cardPurple)}>
        <div className={styles.cardPatientTips}>
          <p className={styles.cardPatientTipsTitle}>Roxie Recommend:</p>
          <p>{body}</p>
          {props.doctors ? (
            <SelectInput
              className={styles.form_control}
              options={props.doctors}
              onChange={e => props.onSelect(e.target.value)} />
          ) : null}
          {props.selectedDoctor ? (
            <div>
              <button className={classname(styles.btnOutline, styles.btnPurpleInv)} onClick={() => props.onClick("download")}>
                <HiDownload />
            &nbsp;Download Referral
          </button>
          &nbsp;&nbsp;
              <button className={classname(styles.btnOutline, styles.btnPurple)} onClick={() => props.onClick("send_email")}>
                Send email
          </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

PatientProfileTips.propTypes = {
  body: PropTypes.string.isRequired,
};

export default PatientProfileTips;
