import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import classname from "classname";
import styles from "./styles.module.scss";

import patientImg from "../../assets/img/patient.png";

function PatientProfileInfoAvatar({
  patientPicture,
  patientFullName,
  patientAge,
  patientId,
  handleSendSMSClick,
  handleInitiateWorkflowClick
}) {
  return (
    <>
      <div
        className={classname(
          styles.cardPatientInfoItem,
          styles.cardPatientInfoAvatar
        )}
      >
        <img
          src={patientPicture}
          alt="Patient Profile"
          className={styles.cardPatientInfoAvatarImage}
        />
        <span className={styles.cardTitleText}>{patientFullName}</span>
        <span className={styles.cardSubtitleText}>{patientAge} years old</span>
        <span>
          <Link to={`/patient/${patientId}/edit`} className={classname(styles.btnOutline, styles.btnPurple)}>
            Edit Profile
          </Link>
          <button className={classname(styles.btnOutline, styles.btnPurple)}
            onClick={handleSendSMSClick}
          >
            Send SMS
          </button>
          <button className={classname(styles.btnOutline, styles.btnPurple)}
            onClick={handleInitiateWorkflowClick}
          >
            Initiate Workflow
          </button>
        </span>
      </div>
    </>
  );
}

PatientProfileInfoAvatar.propTypes = {
  patientPicture: PropTypes.string,
  patientFullName: PropTypes.string,
  patientAge: PropTypes.number,
};

PatientProfileInfoAvatar.defaultProps = {
  patientPicture: patientImg,
  patientFullName: "Gina L. Petrovic",
  patientAge: 74,
};

export default PatientProfileInfoAvatar;
