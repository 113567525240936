import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

function RadioButton(props) {
  return (
    <div className={styles.flex_radio_button}>
      {props.options.map((option, index) => {
        return (
          <div key={index}>
            <input
              id={props.name + option.id}
              name={props.name}
              value={option.name}
              type="radio"
              checked={props.value === option.name}
              required={props.required}
              onChange={props.onChange}
            />
            <label htmlFor={props.name + option.id}>{option.name}</label>
          </div>
        );
      })}
    </div>
  );
}

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

RadioButton.defaultProps = {
  name: "",
  value: "",
  options: [],
  onChange: () => {},
};

export default RadioButton;
