import React, { useEffect, useState } from "react"
import classname from "classname";

import styles from "./styles.module.scss";

import { BsPlusCircle } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import SearchInput from "../../components/inputs/searchinput/SearchInput";

import PatientListTable from "./PatientListTable";

import {
  get as getRequest,
  onFailure,
  onSucess as parserSuccess,
} from "../../api/common";
import { GET_PATIENT_LIST, SEARCH_PATIENT_FALL_RISK } from "../../api/endpoints";

import { getAge, prettyDate } from "../../utils";

function PatientList(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [patients, setPatients] = useState(props.patients);
  const [header, setHeader] = useState(props.header);
  const [sortOrder, setSortOrder] = useState("none");
  const [searchText, setSearchText] = useState("");

  let history = useHistory();

  const getPatients = (response) => {
    console.log(response);
    const results = parserSuccess(response);
    if (results) {
      setPatients(
        results.map(item => {
          return ({
            id: item._id,
            name: item.middleName ? `${item.firstName} ${item.middleName} ${item.lastName}` : `${item.firstName} ${item.lastName}`,
            sex: item.sex,
            age: getAge(item.birthDayDate),
            last_contact: prettyDate(item.lastContact),
            phone: item.phoneNumber,
            risk_change: item.riskChange,
            fall_risk: item.fallRisk,
          })
        })
      );
    } else {
      setPatients(props.patients);
    }
  };

  useEffect(() => {
    Promise.all([
      getRequest(GET_PATIENT_LIST, getPatients, onFailure),
    ]).then(() => {
      setIsLoaded(true);
      setIsTableLoaded(true);
    });
  }, []);

  useEffect(() => {
    console.log(searchText);
  }, [searchText]);

  const onSearchInputChange = evt => {
    const { value } = evt.target;
    setSearchText(value);
  }

  const search = evt => {
    if (evt.key === "Enter") {
      setIsTableLoaded(false);
      if (searchText === "") {
        Promise.all([
          getRequest(GET_PATIENT_LIST, getPatients, onFailure),
        ]).then(() => setIsTableLoaded(true));
      } else {
        Promise.all([
          getRequest(SEARCH_PATIENT_FALL_RISK(searchText), getPatients, onFailure),
        ]).then(() => setIsTableLoaded(true));
      }
    }
  }

  const sort = (field, attr) => {
    setHeader(header => [...header.map(e => {
      if (e.title === field) {
        return { ...e, sortFocus: true }
      } else {
        return { ...e, sortFocus: false }
      }
    })]);
    switch (field) {
      case "Name":
      case "Sex":
      case "Phone":
      case "Risk Change":
      case "Fall Risk":
        if (sortOrder === "none" || sortOrder === "desc") {
          setSortOrder("asc");
          setPatients(patients => [...patients.sort((a, b) => {
            if (a[attr] && b[attr]) {
              return a[attr].localeCompare(b[attr])
            } else {
              return -1;
            }
          })]);
        } else if (sortOrder === "asc") {
          setSortOrder("desc");
          setPatients(patients => [...patients.sort((a, b) => {
            if (a[attr] && b[attr]) {
              return b[attr].localeCompare(a[attr])
            } else {
              return -1;
            }
          })]);
        }
        break;
      case "Age":
        if (sortOrder === "none" || sortOrder === "desc") {
          setSortOrder("asc");
          setPatients(patients => [...patients.sort((a, b) => a[attr] - b[attr])]);
        } else if (sortOrder === "asc") {
          setSortOrder("desc");
          setPatients(patients => [...patients.sort((a, b) => b[attr] - a[attr])]);
        }
        break;
      case "Last Contact":
        if (sortOrder === "none" || sortOrder === "desc") {
          setSortOrder("asc");
          setPatients(patients => [...patients.sort((a, b) => new Date(a[attr]) - new Date(b[attr]))]);
        } else if (sortOrder === "asc") {
          setSortOrder("desc");
          setPatients(patients => [...patients.sort((a, b) => new Date(b[attr]) - new Date(a[attr]))]);
        }
        break;
      default:
        console.log('');
    }
  }

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <div className={classname(styles.card, styles.card_white)}>
            <div className={classname(styles.flex_container, styles.table_header)}>
              <div className={styles.patient_title}>All Patients</div>
              <div className={styles.flex_container}>
                <SearchInput
                  placeholder="Search patients..."
                  onChange={onSearchInputChange}
                  onKeyDown={search} />
                <Button className={styles.add_button} onClick={() => history.push('/patient/create')}>
                  <BsPlusCircle /> Add new
                </Button>
              </div>
            </div>
            <PatientListTable
              header={header}
              patients={patients}
              sort={sort}
              sortOrder={sortOrder}
              isLoaded={isTableLoaded} />
            {/* <div className={styles.table_footer}>
              Show 20 more
            </div> */}
          </div>
        </Content>
        <Footer />
      </Container>
    );
}

PatientList.defaultProps = {
  patients: [],
  header: [
    { title: "Name", attr: "name", sortable: true, sortFocus: false },
    { title: "Sex", attr: "sex", sortable: true, sortFocus: false },
    { title: "Age", attr: "age", sortable: true, sortFocus: false },
    { title: "Last Contact", attr: "last_contact", sortable: true, sortFocus: false },
    { title: "Phone", attr: "phone", sortable: true, sortFocus: false },
    { title: "Risk Change", attr: "risk_change", sortable: true, sortFocus: false },
    { title: "Fall Risk", attr: "fall_risk", sortable: true, sortFocus: false },
    { title: "", attr: "", sortable: false, sortFocus: false },
  ]
}

export default PatientList;
