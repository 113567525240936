import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";

import PatientRegistrationForm from "./PatientRegistrationForm";

import styles from "./styles.module.scss";
import classname from "classname";
import alertify from "alertifyjs";

import {
  get as getRequest,
  post as postRequest,
  onFailure,
  onSucess as parserSuccess,
} from "../../api/common";
import {
  CAREGIVERS_LIST,
  SAVE_PATIENT,
  SAVE_FILE_DOCUMENT
} from "../../api/endpoints";

import { toBase64 } from "../../utils";

function PatientRegistration(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [patient, setPatient] = useState(props.patient);
  const [images, setImages] = useState(props.images);

  const history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    console.log(patient);
  }, [patient]);

  const onChange = async (evt) => {
    const { name, value, nodeName, type } = evt.target;
    if (nodeName === "INPUT") {
      if (type === "text")
        setPatient((patient) => ({ ...patient, [name]: value }));
      if (type === "date" && value)
        setPatient((patient) => ({ ...patient, [name]: new Date(value).toISOString().replace('Z', '+00:00') }));
      if (type === "date" && !value)
        setPatient((patient) => ({ ...patient, [name]: "" }));
      if (type === "radio")
        setPatient((patient) => ({ ...patient, [name]: value }));
      if (type === "file") {
        if (value.length > 0) {
          const b64Img = await toBase64(evt.target.files[0]);
          const imgUrl = URL.createObjectURL(evt.target.files[0]);
          setPatient((patient) => ({ ...patient, [name]: b64Img }));
          setImages((images) => ({ ...images, [name]: imgUrl }));          
          // Promise.all([
          //   postRequest(SAVE_FILE_DOCUMENT, b64Img),
          // ]).then(() => {
          //   setImages((images) => ({ ...images, [name]: imgUrl }));
          // });
        }
      }
    }
    if (nodeName === "SELECT") {
      setPatient((patient) => ({
        ...patient,
        [name]: value,
      }));
    }
  };

  const onSavePatientSuccess = (response) => {
    console.log("RESPUESTA");
    console.log(response);
    // const results = parserSuccess(response);
    if(response.data.code === 500){
      alertify.alert(response.data.message).set("closable", false)
    } else {
      alertify.alert(
        "Patient information saved!",
        "Your patient information has been successfully saved. You will be redirected to the patient list page",
        function () {
          history.push("/patients/list");
        })
        .set("closable", false)
    }
  };

  /* eslint eqeqeq: "off" */
  const validateData = () => {
    if (
      patient.firstName == "" ||
      patient.lastName == "" ||
      patient.birthDayDate == "" ||
      patient.phoneNumber == ""
    ) {
      return false;
    }
    return true;
  };

  const isCaregiverRequired = () => {
    if (
      patient.caregiverFirstName !== "" ||
      patient.caregiverMiddleName !== "" ||
      patient.caregiverLastName !== "" ||
      patient.caregiverPhoneNumber !== "" ||
      patient.caregiverEmail !== ""
    ) {
      return true;
    }
    return false;
  }

  const submit = (evt) => {
    evt.preventDefault();
    setIsLoaded(false);

    // Parse Data
    console.log("Parse Data");

    if (validateData()) {
      let patientInfo = {
        firstName: patient.firstName,
        middleName: patient.middleName,
        lastName: patient.lastName,
        sex: patient.sex,
        birthDayDate: patient.birthDayDate,
        phoneNumber: patient.phoneNumber,
        email: patient.email,
        address: patient.address,
        address2: patient.address2,
        race: patient.race,
        maritalStatus: patient.maritalStatus,
        employmentStatus: patient.employmentStatus,
        photo: patient.photo,
        insuranceCardFront: patient.insuranceCardFront,
        insuranceCardBack: patient.insuranceCardBack,
        driversLicense: patient.driversLicense,
      };
      if (isCaregiverRequired()) {
        patientInfo.caregiver = {
          firstName: patient.caregiverFirstName,
          middleName: patient.caregiverMiddleName,
          lastName: patient.caregiverLastName,
          phoneNumber: patient.caregiverPhoneNumber,
          email: patient.caregiverEmail,
        }
      }
      console.log(patientInfo);
      Promise.all([
        postRequest(SAVE_PATIENT, patientInfo, onSavePatientSuccess, onFailure),
      ]).then(() => {
        setIsLoaded(true);
        setPatient(props.patient);
        setImages(props.images);
      });
    } else {
      console.log("Patient cannot be saved");
      setIsLoaded(true);
    }
  };

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <p className={styles.title}>Patient Registration</p>
          <div className={classname(styles.card, styles.card_white)}>
            <PatientRegistrationForm
              images={images}
              requiredFields={isCaregiverRequired}
              onChange={onChange}
              submit={submit}
              patient={patient}
            />
          </div>
        </Content>
        <Footer />
      </Container>
    );
}

PatientRegistration.defaultProps = {
  patient: {
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "",
    birthDayDate: "",
    phoneNumber: "",
    email: "",
    address: "",
    driversLicense: "",
    race: "",
    maritalStatus: "",
    employmentStatus: "",
    photo: "",
    insuranceCardFront: "",
    insuranceCardBack: "",
    caregiverFirstName: "",
    caregiverMiddleName: "",
    caregiverLastName: "",
    caregiverPhoneNumber: "",
    caregiverEmail: "",
  },
  images: {
    photo: "",
    insuranceCardFront: "",
    insuranceCardBack: "",
    driversLicense: "",
  },
};

export default PatientRegistration;
