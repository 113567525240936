import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import classname from "classname";
import Container from "../../components/container/Container";
import Preloader from "../../components/preloader/Preloader";


import Navbar from "../../components/navbar/Navbar";
import SelectInput from "../../components/inputs/selectinput/SelectInput";
import TextInput from "../../components/inputs/textinput/TextInput";
import alertify from "alertifyjs";
import Content from "../../components/content/Content";
import { fetchVideo } from "../../actions/patient";

import { getAllVideoBy, UPDATE_GRADING } from "../../api/endpoints";
import { post as postRequest, onFailure } from "../../api/common";

const CommonVideoGrading = (props) => {
  const [videoSrc, setVideoSrc] = useState("");
  const videoRef = useRef();
  const patient = props.patient;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);

  const getAllVideoByPatient = async (id) => {
    let videoData = await fetchVideo(getAllVideoBy());

    if (videoData && videoData.code === 200) {
      setVideoSrc(videoData.results);
      // console.log("Allvideo",videoData)
    }
  };

  const timeDifference = (startTime, endTime) => {
    let startTimeArr = startTime.split(":");
    let endTimeArr = endTime.split(":");
    let startTimeData = startTimeArr[0] * 60 + parseInt(startTimeArr[1]);
    let endTimeData = endTimeArr[0] * 60 + parseInt(endTimeArr[1]);
    // if (endTimeData < startTimeData) {
    //   alertify
    //     .alert(
    //       "End time should be greater than start time."
    //     )
    //     .set("closable", false);
    //   return;
    // }
    let timeDiff = endTimeData - startTimeData;
    let secondsDiff = timeDiff % 60;
    let minutesDiff = (timeDiff - secondsDiff) / 60;
    if (minutesDiff >= 2) {
      alertify
        .alert(
          "Difference between start time and end time should be less than 2 minutes."
        )
        .set("closable", false);
      return;
    }
    if (secondsDiff < 10) {
      secondsDiff = "0" + secondsDiff;
    }
    return minutesDiff + ":" + secondsDiff; // Return is MM : SS
  };

  const updateGradingData = (gradingData) => {
    Promise.all([
      postRequest(
        UPDATE_GRADING,
        gradingData,
        onGradingUpdateSuccess,
        onFailure
      ),
    ]);
  };

  const onGradingChange = (e, id) => {
    let videoSrcIndex = videoSrc.findIndex((obj) => obj._id === id);
    let tempArray = videoSrc;
    let { name, value } = e.target;
    let tempObj = { ...videoSrc[videoSrcIndex], [name]: value };
    if (tempObj.startTime && tempObj.endTime) {
      let diffTime = timeDifference(tempObj.startTime, tempObj.endTime);
      tempObj = { ...tempObj, diffTime };
    }
    if (tempObj.qualityControl && tempObj.startTime && tempObj.endTime) {
      updateGradingData(tempObj);
    }
    tempArray[videoSrcIndex] = tempObj;
    setVideoSrc([...tempArray]);
  };

  
  const onGradingUpdateSuccess = (response) => {};

  useEffect(() => {
    Promise.all([
      getAllVideoByPatient()
    ]).then(() => {
      setIsLoaded(true);
      setIsTableLoaded(true);
    });
  }, [])


  return !isLoaded ? (
    <Preloader />
  ) : (
    <Container>
    <div>
      <Navbar />
      <Content>
        <div className={styles.commonback}>
      {videoSrc ? (
        videoSrc.map((item) => (
          <div className={styles.section}>
            <div className={styles.main}>
              <div className={styles.videoSection}>
                <video
                  ref={videoRef}
                  src={item.url}
                  width="300"
                  height="200"
                  controls
                />
                <div className={styles.left_align}></div>
              </div>
              <div className={styles.videoContent}>
                <h1 className={styles.title}>{item.name}</h1>
                <div className={styles.qualitymain}>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Quality Control:</label>
                    </div>
                    <div className={styles.left_align}>
                      <SelectInput
                        name="qualityControl"
                        className={styles.form_control}
                        options={props.selectInputOption}
                        onChange={(e) => onGradingChange(e, item._id)}
                        value={item.qualityControl}
                        required
                      />
                    </div>
                  </div>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Start time:</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="startTime"
                        className={styles.form_control}
                        maxLength={50}
                        value={item.startTime}
                        onChange={(e) => onGradingChange(e, item._id)}
                        placeholder="0:00"
                        type="time"
                        required
                      />
                    </div>
                  </div>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>End time:</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="endTime"
                        className={styles.form_control}
                        value={item.endTime}
                        onChange={(e) => onGradingChange(e, item._id)}
                        placeholder="0:00"
                        type="time"
                        required
                      />
                    </div>
                  </div>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Result:</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="diffTime"
                        className={classname(styles.form_control, styles.diffTime)}
                        placeholder="0:00"
                        // onChange={(e)=> onGradingChange(e, item._id)}
                        value={item.diffTime}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <h2>No Videos Found ..</h2>
      )}
      </div>
          </Content>

    </div>
    </Container>
  );
};


CommonVideoGrading.defaultProps = {
  selectInputOption: [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ],
};

export default CommonVideoGrading;
