import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import styles from "./styles.module.scss";

const TextInput = React.forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      name={props.name}
      value={props.value}
      type={props.type}
      className={classname(styles.t_input, props.className)}
      placeholder={props.placeholder}
      required={props.required}
      maxLength={props.maxLength}
      minLength={props.minLength}
      onFocus={props.onFocus}
      onChange={props.onChange}
      {...props}
    />
  )
});

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  pĺaceholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
}

TextInput.defaultProps = {
  name: "",
  value: "",
  className: "",
  type: "text",
  placeholder: "",
  required: false,
  maxLength: 200,
  minLength: 1,
  onChange: () => { },
  onFocus: () => { },
}

export default TextInput;
