import jwtDecode from 'jwt-decode';
import { writePermissions } from '../actions/auth'
import { isArrayInArray } from './index'

export const authorize = async (requiredPermission = [], functionAccessToken, storePermissions=true) => {
  const accessToken = await functionAccessToken();
  const decoded =  jwtDecode(accessToken)
  const {permissions} = decoded;
  if (storePermissions) {
    writePermissions(permissions);
  }
  if (requiredPermission.length > 0) {
    return isArrayInArray(permissions, requiredPermission);
  } else {
    // does not require permission only store permission
    return true;
  }

}
