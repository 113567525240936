import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import alertify from "alertifyjs";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";
import FallRiskChar from "../../components/chars/FallRisk";
import AbcScaleChar from "../../components/chars/AbcScale";
import SitStandChar from "../../components/chars/SitStand";
import Dialog from "../../components/dialog/Dialog";
import TextInput from "../../components/inputs/textinput/TextInput";
import SelectInput from "../../components/inputs/selectinput/SelectInput";

import PatientProfileEngagementChart from "./PatientProfileEngagementChart";
import PatientProfileInfoAvatar from "./PatientProfileInfoAvatar";
import PatientProfileInfoText from "./PatientProfileInfoText";
import PatientProfileSummary from "./PatientProfileSummary";
import PatientProfileTips from "./PatientProfileTips";

import styles from "./styles.module.scss";
import classname from "classname";

import {
  getStatisticsABCUrl,
  getStatisticsFiveStandUrl,
  getStatisticsTextMsgUrl,
  getAllVideoByPatientIdUrl,
  REF_DOCTOR,
  SEND_MESSAGE,
  UPDATE_GRADING,
  INITIATE_WORKFLOW,
  SAVE_FILE_DOCUMENT
} from "../../api/endpoints";

import {
  post as postRequest,
  onFailure,

} from "../../api/common";

import { getAge } from "../../utils";
import { parseMinutes, parseDate } from "../../utils/parsers";
import { can } from "../../actions/auth";
import { fetchVideo } from "../../actions/patient";

function PatientProfile(props) {
  const patient = props.patient;
  const doctors = props.doctors;
  const [caregiver, setCaregiver] = useState(props.caregiver);
  const [healthCareSystem, setHealthcareSystem] = useState(
    props.healthcareSystem
  );
  const [averages, setAverages] = useState(props.averages);
  const [riskChange, setRiskChange] = useState(props.riskChange);
  const [selectedDoctor, setSelectedDoctor] = useState("");

  const [abcUrl, setAbcUrl] = useState("");
  const [sitStandUrl, setSitStandUrl] = useState("");
  const [textMsgUrl, setTextMsgUrl] = useState("");
  const [forceRefreshPinChar, setForceRefreshPinChar] = useState(true);
  const videoRef = useRef();
  const [videoSrc, setVideoSrc] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const phoneNumber = props.patient?.phoneNumber;
    if (phoneNumber) {
      setAbcUrl(getStatisticsABCUrl(phoneNumber));
      setSitStandUrl(getStatisticsFiveStandUrl(phoneNumber));
      setTextMsgUrl(getStatisticsTextMsgUrl(phoneNumber));
      getVideoOfPatient(props.patient?._id);
    }
  }, [props.patient]);

  const getVideoOfPatient = async (id) => {
    let videoData = await fetchVideo(getAllVideoByPatientIdUrl(id));
    if( videoData && videoData.code === 200){
      setVideoSrc(videoData.results);
    }
  }

  useEffect(() => {
    setCaregiver(props.caregiver);
    setHealthcareSystem(props.healthcareSystem);
    setAverages(props.averages);
    setRiskChange(props.riskChange);
  }, [props]);

  if (!patient) {
    return <Preloader />;
  }

  const onFilterEngagement = (selectedFilter) => {
    const phoneNumber = props.patient?.phoneNumber;
    setAbcUrl(getStatisticsABCUrl(phoneNumber, selectedFilter));
    setSitStandUrl(getStatisticsFiveStandUrl(phoneNumber, selectedFilter));
    setTextMsgUrl(getStatisticsTextMsgUrl(phoneNumber, selectedFilter));
    // This update all children component
    setForceRefreshPinChar(!forceRefreshPinChar);
  };

  const onRequestDownloadSuccess = (response) => {
    console.log(response);
    const { msg, payload } = response.data;
    if (msg === "successfully") {
      if (payload.download) {
        const fileName = payload.download.split("/");
        fetch(process.env.REACT_APP_MAIL_DOMAIN + payload.download, {
          method: "POST",
          headers: {
            "Content-Type": "application/pdf",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName[fileName.length - 1]}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      } else {
        alert("Email sent");
      }
    } else {
      alert(msg);
    }
    setSelectedDoctor("");
  };

  const onRequestDownload = (option) => {
    const params = {
      doctor_id: selectedDoctor,
      patient_phone_number: patient.phoneNumber,
      options: [option],
    };
    Promise.all([
      postRequest(REF_DOCTOR, params, onRequestDownloadSuccess, onFailure),
    ]);
  };

  const onMessageSendSuccess = (response) => {
    alertify.alert(
      "Your message information has been sent successfully.")
      .set("closable", false);
    
      showAndHideSMSSend();
  };

  const onGradingUpdateSuccess = (response) => {
    
  };

  const sendMessage = () => {
    const params = {
      _id: props.patient._id,
      message
    };
    Promise.all([
      postRequest(SEND_MESSAGE, params, onMessageSendSuccess, onFailure),
    ]);
  };

  const showAndHideSMSSend = () => {
    setShowDialog(prev => !prev);
    setMessage('');
  };

  const onInitiateWorkflowSuccess = (response) => {
    alertify.alert(
      "Your workflow has been successfully initiated.")
      .set("closable", false);
  };

  const handleInitiateWorkflowClick = () => {
    const params = {
      patientPhone: props.patient.phoneNumber,
      workflowId: 'MainWorkflow'
    };
    Promise.all([
      postRequest(INITIATE_WORKFLOW, params, onInitiateWorkflowSuccess, onFailure),
    ]);
  };

  const timeDifference = (diffTime) => {
    // let startTimeArr= startTime.split(':');
    // let endTimeArr= endTime.split(':');
    // let startTimeData = startTimeArr[0]*60 + parseInt(startTimeArr[1]);
    // let endTimeData = endTimeArr[0]*60 + parseInt(endTimeArr[1]);
    // let timeDiff = endTimeData - startTimeData;
    // let secondsDiff = diffTime % 60;
    // let minutesDiff = (timeDiff - secondsDiff) / 60;
    if(diffTime >= 120){
      alertify.alert(
        "Difference between start time and end time should be less than 2 minutes")
        .set("closable", false);
      return;
    }
    // if (secondsDiff < 10) {
    //   secondsDiff = "0" + secondsDiff;
    // }
    return diffTime;
 }

 const updateGradingData = (gradingData) => {
  Promise.all([
    postRequest(UPDATE_GRADING, gradingData, onGradingUpdateSuccess, onFailure),
  ]);
 }

  const onGradingChange = (e, id) => {
    let videoSrcIndex = videoSrc.findIndex((obj => obj._id === id));
    let tempArray = videoSrc;
    let { name, value } = e.target;
    let tempObj = {...videoSrc[videoSrcIndex],[name]: value}
    if(tempObj.diffTime) {
      let diffTime = timeDifference(tempObj.diffTime);
      tempObj = {...tempObj, diffTime}
    }
    if(tempObj.qualityControl === "Yes" && tempObj.diffTime) {
      updateGradingData(tempObj);
    }
    tempArray[videoSrcIndex] = tempObj;
    setVideoSrc([...tempArray]);
  }

  return (
    <Container>
      <Navbar />
      { showDialog &&
        <Dialog
        children={
          <>
          <div className={styles.div_area}>
            <label>Message</label>
            <textarea 
              maxLength={250}
              value={message}
              onChange={e=>setMessage(e.target.value)}
            />
          </div>
          <div className={styles.btn_div_area}>
            <div className={styles.btn_div}>
              <button
                type="button"
                className={classname(styles.btnOutline, styles.btnPurple)}
                onClick={()=>showAndHideSMSSend()}
              >Cancel</button>
              <button
                type="button"
                className={classname(styles.btnOutline, styles.btnPurple)}
                onClick={()=>sendMessage()}
              >Send</button>
            </div>
          </div>
          </>
        }
        />
      }
      <Content>
        <p className={styles.title}>Patient Profile</p>
        <div className={classname(styles.card, styles.cardWhite)}>

          <div className={styles.cardPatientInfo}>
            <PatientProfileInfoAvatar
              patientPicture={props.patientPicture}
              patientFullName={`${patient.firstName} ${patient.lastName}`}
              patientAge={getAge(patient.birthDayDate)}
              patientId={props.patient?._id}
              handleSendSMSClick={showAndHideSMSSend}
              handleInitiateWorkflowClick={handleInitiateWorkflowClick}
            />
            <PatientProfileInfoText
              subtitle1="Date of Birth"
              text1={parseDate(patient.birthDayDate)}
              subtitle2="Sex"
              text2={patient.sex || ""}
            />
            <PatientProfileInfoText
              subtitle1="Caregiver"
              text1={caregiver && `${caregiver.firstName} ${caregiver.lastName}`}
              subtitle2="Address"
              text2={patient.address || ""}
            />
            <PatientProfileInfoText
              subtitle1="Phone Number"
              text1={patient.phoneNumber}
              subtitle2="Email"
              text2={patient.email || ""}
            />
            <div
              className={classname(
                styles.cardPatientInfoItem,
                styles.cardPatientInfoText
              )}
            >
              <span className={styles.cardSubtitleText}>Driver's license</span>
              <img
                src={patient.driversLicense}
                alt="Patient Profile"
                // className={styles.navbarPictureIcon}
                height="120"
                width="120"
              />
            </div>
          </div>
        </div>
        {averages.length > 0 && (
          <>
            <p className={styles.subtitle}>At a Glance</p>
            <PatientProfileSummary
              riskChangeValue={parseInt(riskChange)}
              assessmentValue={props.sitToStand ? props.sitToStand.diffTime : 0}
              fallRiskValue={
                averages[1]
                  ? parseFloat(averages[1].avg["$numberDecimal"]).toFixed(2)
                  : 0
              }
              surveyValue={
                averages[2]
                  ? parseFloat(averages[2].avg["$numberDecimal"]).toFixed(2)
                  : 0
              }
            />
          </>
        )}
        <div>
        <div>
          {can(["roxie_recommend:patient_profile"]) && (
            <PatientProfileTips
              body={props.patientTipsBody.replace(
                "{patientName}",
                patient.firstName + " " + patient.lastName
              )}
              doctors={doctors}
              selectedDoctor={selectedDoctor}
              onSelect={setSelectedDoctor}
              onClick={onRequestDownload}
            />
          )}
          </div>
          <div className={styles.roxie_video}>
            {
              videoSrc ? videoSrc.map((item)=>(
                <div>
                  <video ref={videoRef} src={item.url} width="300" height="200" controls/>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Quality Control:</label>
                    </div>
                    <div className={styles.left_align}>
                      <SelectInput
                        name="qualityControl"
                        className={styles.form_control}
                        options={props.selectInputOption}
                        onChange={(e)=> onGradingChange(e, item._id)}
                        value={item.qualityControl}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className={classname(styles.p_info)}>
                    <label>Use of arms</label>
                    <SelectInput
                      name="useOfArms"
                      className={styles.form_control}
                      options={props.selectInputOption}
                      onChange={()=> onGradingChange()}
                      required
                    />
                  </div>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Start time:</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="startTime"
                        className={styles.form_control}
                        maxLength={50}
                        value={item.startTime}
                        onChange={(e)=> onGradingChange(e, item._id)}
                        type="time"
                        required
                      />
                    </div>
                  </div>
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>End time:</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="endTime"
                        className={styles.form_control}
                        value={item.endTime}
                        onChange={(e)=> onGradingChange(e, item._id)}
                        type="time"
                        required
                      />
                    </div>
                  </div>*/}
                  <div className={classname(styles.p_info)}>
                    <div className={styles.right_align}>
                      <label>Result(in Seconds):</label>
                    </div>
                    <div className={styles.left_align}>
                      <TextInput
                        name="diffTime"
                        className={classname(styles.form_control, styles.diffTime)}
                        placeholder="00"
                        value={item.diffTime}
                        onChange={(e)=> onGradingChange(e, item._id)}
                        maxLength={3}
                        required
                      />
                    </div>
                  </div>
                </div>
              )):
              <h2> No video found... </h2>
            }
          </div>
        </div>
        <p className={styles.subtitle}>Patient Data</p>
        <div className={styles.cardPatientData}>
          <div className={styles.cardPatientDataCompact}>
            <div
              className={classname(
                styles.card,
                styles.cardPatientDataItem,
                styles.cardWhite
              )}
            >
              <PatientProfileEngagementChart
                remoteData={true}
                abcUrl={abcUrl}
                sitStandUrl={sitStandUrl}
                textMsgUrl={textMsgUrl}
                forceRefresh={forceRefreshPinChar}
                onFilterChange={onFilterEngagement}
              />
            </div>
            <div
              className={classname(
                styles.card,
                styles.cardPatientDataItem,
                styles.cardWhite
              )}
            >
              <FallRiskChar remoteData={true} patient={props.patient} />
            </div>
          </div>
          <div
            className={classname(
              styles.card,
              styles.cardPatientDataItem,
              styles.cardWhite
            )}
          >
            <AbcScaleChar remoteData={true} patient={props.patient} />
          </div>
          <div
            className={classname(
              styles.card,
              styles.cardPatientDataItem,
              styles.cardWhite
            )}
          >
            <SitStandChar remoteData={true} patient={props.patient} />
          </div>
        </div>
      </Content>
      <Footer />
    </Container>
  );
}

PatientProfile.propTypes = {
  patientPicture: PropTypes.string,
  patient: PropTypes.object,
  caregiver: PropTypes.object,
  healthcareSystem: PropTypes.object,
  patientHealthInsurance: PropTypes.string,
  averages: PropTypes.array,
  riskChange: PropTypes.number,
  patientTipsBody: PropTypes.string,
  selectInputOption: PropTypes.arrayOf,
};

PatientProfile.defaultProps = {
  caregiver: {},
  healthcareSystem: {},
  averages: [],
  riskChange: 0,
  patientHealthInsurance: "",
  patientTipsBody: `Based on the data submitted by {patientName}, her/his chances of falling are
  high. Physical therapy may reduce her/his risk of accidental falls.
  Download a referral to get her/his started on physio!`,
  selectInputOption: [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ],
};

export default PatientProfile;
