import React from "react";
import PropTypes from "prop-types";

import classname from "classname";
import styles from "./styles.module.scss";

function PatientProfileInfoText({ subtitle1, text1, subtitle2, text2 }) {
  return (
    <>
      <div
        className={classname(
          styles.cardPatientInfoItem,
          styles.cardPatientInfoText
        )}
      >
        <span className={styles.cardSubtitleText}>{subtitle1}</span>
        <span className={styles.cardTextCollapse}>{text1}</span>
        <br />
        <span className={styles.cardSubtitleText}>{subtitle2}</span>
        <span className={styles.cardTextCollapse}>{text2}</span>
      </div>
    </>
  );
}

PatientProfileInfoText.propTypes = {
  subtitle1: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
};

export default PatientProfileInfoText;
