import React from "react";

import styles from "./styles.module.scss";

function HomePopulation({ low, medium, high }) {
  return (
    <div>
      <div className={styles.barsContainer}>
        <div className={styles.barsContent}>
          <div
            className={styles.barsFillLow}
            style={{ marginRight: `${100 - low}%` }}
          ></div>
        </div>
        <div className={styles.barsInfo}>
          <span>Low</span>
          <span>{low} %</span>
        </div>
      </div>
      <div className={styles.barsContainer}>
        <div className={styles.barsContent}>
          <div
            className={styles.barsFillMedium}
            style={{ marginRight: `${100 - medium}%` }}
          ></div>
        </div>
        <div className={styles.barsInfo}>
          <span>Medium</span>
          <span>{medium} %</span>
        </div>
      </div>
      <div className={styles.barsContainer}>
        <div className={styles.barsContent}>
          <div
            className={styles.barsFillHigh}
            style={{ marginRight: `${100 - high}%` }}
          ></div>
        </div>
        <div className={styles.barsInfo}>
          <span>High</span>
          <span>{high} %</span>
        </div>
      </div>
    </div>
  );
}

HomePopulation.defaultProps = { low: 27, medium: 55, high: 18 };

export default HomePopulation;
