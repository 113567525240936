import React, { useEffect, useState } from "react";

import Container from "../../components/container/Container";
import Content from "../../components/content/Content";
import Navbar from "../../components/navbar/Navbar";
import Preloader from "../../components/preloader/Preloader";
import Footer from "../../components/footer/Footer";
import AdministrationBoard from "./AdministrationBoard";

import styles from "./styles.module.scss";
import classname from "classname";

function Administration(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return !isLoaded ? (
    <Preloader />
  ) : (
      <Container>
        <Navbar />
        <Content>
          <p className={styles.title}>Administration Board</p>
          <div className={classname(styles.card, styles.card_white)}>
            <AdministrationBoard />
          </div>
        </Content>
        <Footer />
      </Container>
    )
}

export default Administration;