import patientImg from "../assets/img/patient.png";

export function parseDate(date) {
  const dateP = new Date(date);
  return dateP.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function parseMinutes(minutesInDecimal) {
  const totalSeconds = Math.round(minutesInDecimal * 60);
  const minutes = Math.trunc(totalSeconds / 60);
  const restSeconds = totalSeconds - minutes * 60;

  if (restSeconds === 0) return `${minutes.toString()}:00`;
  else if (restSeconds < 10) return `${minutes.toString()}:0${restSeconds}`;
  else return `${minutes.toString()}:${restSeconds}`;
}

export function parsePatientChartData(obj, groupBy = "") {
  /**
   * {results: [{createdAt: DATA, answer: {value: DATA}}, {}, ..., {}]}
   *
   * createdAt: X
   * value: Y
   * groupBy: month | week
   */
  if (["month", "week"].includes(groupBy)) {
    let results = obj
      .map((item) => ({
        x: `${item._id.month}-${item._id.year}`,
        y: item.count,
      }))
      .sort((a, b) => a.x - b.x);
    return results;
  } else {
    return obj.results
      .map((item) => ({
        x: new Date(item.createdAt).getTime(),
        y: parseInt(item.answer.value),
      }))
      .sort((a, b) => a.x - b.x);
  }
}

export function parseRisingRiskChangeList(patientsList) {
  /**
   * Object Model
   {
      fullName: "Peter Cheung",
      date: "04/02/21",
      fallRisk: 8,
      riskChange: 2,
      image: patientImg,
    }
   */
  return patientsList.map((item) => ({
    fullName: `${item.patients.firstName} ${item.patients.lastName}`,
    date: parseDate(item.createdAt),
    riskChange: item.answer.value,
    image: patientImg, // TODO
    fallRisk: 9, // TODO
  }));
}
