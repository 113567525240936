import React from "react";

import Button from "../../components/button/Button";

import styles from "./styles.module.scss";
import { IoCheckmarkCircleOutline, IoWarningOutline } from "react-icons/io5";

function VideoAssessmentSteps(props) {
  const {
    title = "Test Instructions",
    body = "Tap on the video below to watch how the  5x Sit-to-Stand test is performed. Return to home to submit your own video.",
    video = "https://www.youtube.com/embed/h05PD0aY6uk",
    onReturn = () => {},
  } = props;

  return (
    <>
      <h2>{title}</h2>
      <p className={styles.body}>{body}</p>
      <div className={styles.videoContentExample}>
        <iframe
          src={video}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video Assessment"
        />
      </div>
      <Button className={styles.buttonEmptyFill} onClick={onReturn}>
        Return to Home
      </Button>
    </>
  );
}

export default VideoAssessmentSteps;
