import React, { useState } from "react";
import PropTypes from "prop-types";
import classname from "classname";

import { BiSortDown, BiSortUp } from "react-icons/bi";

import styles from "./styles.module.scss";

function HospitalListTable(props) {

  const sortArrows = () => {
    if (props.sortOrder === "asc") {
      return <BiSortDown />;
    } else if (props.sortOrder === "desc") {
      return <BiSortUp />;
    }
    return null;
  };

  return (
    <>
      <div className={styles.table_responsive}>
        <table className={styles.patient_table}>
          <thead>
            <tr>
              {props.header.map((column, index) => {
                if (column.sortable) {
                  return (
                    <th
                      key={index}
                      className={classname(styles.separator, styles.sortable)}
                      onClick={() => props.sort(column.title, column.attr)}
                    >
                      <div className={styles.column_head}>
                        <span style={{ marginRight: 10 }}>{column.title}</span>
                        {column.sortFocus ? sortArrows() : null}
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className={styles.separator}>
                      {column.title}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {props.hospitals.length > 0 && props.isLoaded
              ? props.hospitals.map((hospitals, index) => {
                  return (
                    <tr key={index}>
                      <td align="center" className={styles.table_row}>
                        {hospitals.healthCareName}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {hospitals.address}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {hospitals.phone}
                      </td>
                      <td align="center" className={styles.table_row}>
                        {hospitals.email}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      {!props.isLoaded ? (
        <div className={styles.table_footer}>Searching for hospitals...</div>
      ) : null}
      {props.hospitals.length < 1 && props.isLoaded ? (
        <div className={styles.table_footer}>No results found</div>
      ) : null}
    </>
  );
}

HospitalListTable.propTypes = {
  header: PropTypes.array,
  hospitals: PropTypes.array,
  sort: PropTypes.func,
  sortOrder: PropTypes.string,
  isLoaded: PropTypes.bool,
};

HospitalListTable.defaultProps = {
  header: [],
  hospitals: [],
  sort: () => {},
  sortOrder: "",
  isLoaded: false,
};

export default HospitalListTable;
