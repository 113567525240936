// import axios from 'axios'
// import {API_DOMAIN} from '../api/endpoints';

import { isArrayInArray } from "../utils";
import axios from "axios";
import {
  URL_ROLES_FOR_CURRENT_USER,
  URL_FETCH_CURRENT_USER_PROFILE,
} from "../api/endpoints";

export const fetchUserProfile = async (token) => {
  const res = await axios.post(URL_FETCH_CURRENT_USER_PROFILE, {
    user_id: token,
  });
  return res.data.results;
};

// const writeUser = (user) => {
//   localStorage.setItem('current_user', JSON.stringify(user));
// }

export const getCurrentUser = () => {
  let string_json = localStorage.getItem("current_user");
  return JSON.parse(string_json);
};

export const writePermissions = (permissions = []) => {
  localStorage.setItem("permissions", JSON.stringify(permissions));
};

export const getPermissions = () => {
  let string_json = localStorage.getItem("permissions");
  return JSON.parse(string_json);
};

export const can = (checkPermissions = []) => {
  const currentPermissions = getPermissions() || [];
  return isArrayInArray(currentPermissions, checkPermissions);
};

export const DEFAULT_SCOPE_AUTH0 = "";

export const fetchRoles = async (userId) => {
  const res = axios.get(URL_ROLES_FOR_CURRENT_USER, {
    params: { user_id: userId },
  });
  return res.data;
};
