import React, { useEffect, useState } from "react";
import Preloader from "../../components/preloader/Preloader";
import * as authActions from "./../../actions/auth";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// This view is after_login redirect
const Authorize = ({ location }) => {
  const [redirectUri, setRedirectUri] = useState("");
  const { user } = useAuth0();
  useEffect(() => {
    authActions
      .fetchUserProfile(user.sub)
      .then((currentUser) => {
        console.log(currentUser);
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        //TODO: improve this for check if is patient or another kind of user
        const userMetadata = currentUser.user_metadata;
        if (userMetadata && userMetadata.phoneNumber) {
          // user is a patient, don't forget improve this condition from back role
          setRedirectUri("/patient_profile");
        } else if(currentUser && currentUser.email === 'superadmin@roxie.com'){
          setRedirectUri('/hospitals/list');
        }else {
          setRedirectUri("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (redirectUri) {
    return <Redirect to={redirectUri} />;
  }

  return <Preloader />;
};

Authorize.propTypes = {};

Authorize.defaultProps = {};

export default Authorize;
