import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { fetchAbcData, parser2 } from '../../actions/char';
import { DEFAULT_GROUP_BY, PERMITTED_GROUP_BY } from '../../utils/graphic-settings';


import "react-vis/dist/style.css";
import {
  XAxis,
  YAxis,
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  LineMarkSeries,
} from "react-vis";
import styles from '../../pages/patient/styles.module.scss'
import Preloader from '../preloader/Preloader'
import { getStatisticsFiveStandUrl } from '../../api/endpoints'

const chartColorLine = '#FC6366';

const SitStand = (props) => {
  const {phoneNumber} = props.patient;
  const {remoteData} = props
  const [sitStandData, setSitStandData] = useState([]);
  const [groupBy, setGroupBy] = useState(props.groupBy);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (remoteData) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (groupBy && props.onFilterChange) {
      props.onFilterChange(groupBy);
    }
    if (remoteData) {
      //reload because filter has changed
      fetchData();
    }
  }, [groupBy]);

  const fetchData = () => {
    setLoading(true);
    const url = getStatisticsFiveStandUrl(phoneNumber, groupBy);
    fetchAbcData(url).then(data => {
      setSitStandData(parser2(data, groupBy));
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  return <>
    <div className={styles.patientChartTitle}>
      <strong>5 Times Sit-to-Stand</strong>
      <select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
        {PERMITTED_GROUP_BY.map((pg) => <option key={pg.id} value={pg.id}>{pg.text}</option>)}
      </select>
    </div>
    <ul className={styles.patientChartLegend}>
      <li style={{ color: chartColorLine }}>
        <span>Time to Completion</span>
      </li>
    </ul>
    <div className={styles.patientChartGraph}>
      {loading ? <Preloader fullScreen={false} /> :
      <FlexibleXYPlot xType="ordinal">
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-30} />
        <YAxis />
        <LineMarkSeries
          style={{
            strokeWidth: "2px",
          }}
          lineStyle={{ stroke: chartColorLine }}
          markStyle={{ stroke: chartColorLine }}
          color="white"
          curve={"curveMonotoneX"}
          data={sitStandData}
        />
      </FlexibleXYPlot> }
    </div>
  </>;
};

SitStand.propTypes = {
  patient: PropTypes.object,
  groupBy: PropTypes.string,
  remoteData: PropTypes.bool
};

SitStand.defaultProps = {
  patient: {},
  groupBy: DEFAULT_GROUP_BY,
  remoteData: true
};


export default SitStand;
