import React from "react";
import PropTypes from "prop-types";
import classname from "classname";

import styles from "./styles.module.scss";

function Button(props) {
  return (
    <button
      type={props.type}
      className={classname(styles.r_button, props.className)}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

Button.defaultProps = {
  type: "button",
  className: "",
  disabled: false,
  onClick: () => { }
}

export default Button;