import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Preloader from "../../components/preloader/Preloader";
import Container from "../../components/container/Container";
import Button from "../../components/button/Button";

import logo from "../../assets/img/logo.png";

import styles from "./styles.module.scss";
import VideoRecord from "./VideoRecord";
import VideoAssessmentDone from "./VideoAssessmentDone";
import VideoAssessmentSteps from "./VideoAssessmentSteps";

import {
  postNoOrg as postRequest,
  onFailure,
  onSucess as parserSuccess,
} from "../../api/common";

import { VALIDATE_VIDEO_UID } from "../../api/endpoints";

function VideoAssessment(props) {
  const {
    body1 = "The Five Times Sit-to-Stand Test is used to determine fall risk by assessing your lower extremity strength, transitional movements and core balance.",
    body2 = "Complete the test and submit a video recording of it for a physical assessment by Roxie and your care professional.",
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [videoPermission, setVideoPermission] = useState(false);
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [showVideoSteps, setShowVideoSteps] = useState(false);
  const [videoUploadedResult, setVideoUploadedResult] = useState({
    success: true,
    extraParams: {},
  });
  const { uid } = useParams();

  function onUploadVideo(success, extraParams) {
    setVideoUploadedResult({ success, extraParams });
    setVideoUploaded(true);
  }

  function onValidateVideoUID(response) {
    const results = parserSuccess(response);
    const { found, done } = results;

    if (found && !done) {
      setVideoPermission(true);
    } else {
      setVideoPermission(false);
    }
  }

  useEffect(() => {
    Promise.all([
      postRequest(VALIDATE_VIDEO_UID, { uid }, onValidateVideoUID, onFailure),
    ]).then(() => {
      setIsLoaded(true);
    });
  }, []);

  return !isLoaded ? (
    <Preloader />
  ) : (
    <Container>
      {videoPermission ? (
        <div className={styles.videoContainer}>
          <div className={styles.videoContent}>
            <div className={styles.center}>
              <img src={logo} alt="Roxie Logo" />
              {videoUploaded ? (
                <VideoAssessmentDone success={videoUploadedResult.success} />
              ) : showVideoSteps ? (
                <VideoAssessmentSteps
                  onReturn={() => setShowVideoSteps(false)}
                />
              ) : (
                <>
                  <h2>5x Sit-to-Stand Test</h2>
                  <p className={styles.titlePurple}>Video Assessment</p>
                  <p className={styles.body}>{body1}</p>
                  <p className={styles.body}>{body2}</p>
                  <VideoRecord videoUID={uid} onUpload={onUploadVideo} />
                  <Button
                    className={styles.buttonEmpty}
                    onClick={() => setShowVideoSteps(true)}
                  >
                    See Instructions
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1 className={styles.body2}>Unauthorized</h1>
          <p className={styles.body2}>Your link is not valid or expire!</p>
        </>
      )}
    </Container>
  );
}

export default VideoAssessment;
